<template>
  <div class="client-page">
    <div class="our-clients-intro-container">
      <div class="our-clients-intro-text-container">
        <h1>A portfolio of some of our completed project work</h1>
      <h3 class="our-clients-subtext"> From <em>Ecom</em> to complete design and development of 
      <em>Business Software Systems</em>, here is a selection of work for our clients.
      </h3>      
      </div>      
    </div>

    <div class="container">      
      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
          <div class="tile-container">

            <div class="tile">
              <a class="tile-inner">                
                <img src="@/img/clients/staffs-c-o-c-holding-img.jpg" alt="Staffordshire Chamber of Commerce Logo">
                <div class="tile-text">
                  <h4>
                    Staffordshire Chamber of Commerce - RBA
                  </h4>
                  <p>
                    Software Development - Hosting
                  </p>
                </div> 
              </a>
            </div>
            
            <div class="tile">
              <a class="tile-inner" href="https://www.linkedin.com/posts/cyberkiln_digitalupskilling-nscg-education-activity-7082741095050145793-hDLn/" target="_blank">                
                <img src="@/img/clients/nscg-client-img.png" alt="NSCG Logo">
                <div class="tile-text">
                  <h4>
                    Newcastle and Stafford College Group
                  </h4>
                  <p>
                    Consultancy - Training
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://lymerdoors.co.uk/" target="_blank">                
                <img src="@/img/clients/lymers-web.png" alt="Staffordshire Chamber of Commerce Logo">
                <div class="tile-text">
                  <h4>
                    Lymers
                  </h4>
                  <p>
                    Web Design - Web Development - Hosting
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://complete-communications.co.uk/" target="_blank">                
                <img src="@/img/clients/complete-communication.png" alt="Complete Communications Staffordshire Logo">
                <div class="tile-text">
                  <h4>
                    Complete Communications Staffordshire
                  </h4>
                  <p>
                    Web Design - Web Development - Hosting
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://www.staffs.ac.uk/" target="_blank">                
                <img src="@/img/clients/staffsuni.jpg" alt="Staffordshire University Logo">
                <div class="tile-text">
                  <h4>
                    Staffordshire University
                  </h4>
                  <p>
                    Consultancy - Training
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://www.time4sportuk.com/t4w/" target="_blank">
                <img src="@/img/clients/t4s_1.jpg" alt="Time4Sport UK logo">
                <div class="tile-text">
                  <h4>
                    Time 4 Sport
                  </h4>
                  <p>
                    Software & App - Consultancy
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://bestkept.community/" target="_blank">
                <img src="@/img/clients/BKVC.jpg" alt="Best Kept Village Community Competition logo">
                <div class="tile-text">
                  <h4>
                    Best Kept Village
                  </h4>
                  <p>
                    Web & Software Development - Hosting
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://www.rr-groundworks.co.uk/" target="_blank">                
                <img src="@/img/clients/RR-ground.jpg" alt="RR Groundworks logo">
                <div class="tile-text">
                  <h4>
                    RR Groundworks
                  </h4>
                  <p>
                    Web Design - Hosting - Domain
                  </p>
                </div> 
              </a>
            </div> 

            <div class="tile">
              <a class="tile-inner" href="https://charliebrownshairstudio.com" target="_blank">                
                <img src="@/img/clients/CharlieB.jpg" alt="Charlie Browns Logo">
                <div class="tile-text">
                  <h4>
                    Charlie Brown
                  </h4>
                  <p>
                    Web Design - Hosting - Domain
                  </p>
                </div>                  
              </a>              
            </div>          

            <div class="tile">
              <a class="tile-inner">
                <img src="@/img/clients/heaward.jpg" alt="Heaward Solutions Logo">
                <div class="tile-text">
                  <h4>
                    Heaward Solutions
                  </h4>
                  <p>
                    Web Design
                  </p>
                </div> 
              </a>              
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://www.msimpsonandsons.co.uk/" target="_blank">
                <img src="@/img/clients/simpson.jpg" alt="M Simpson and Sons Logo">
                <div class="tile-text">
                  <h4>
                    M Simpson & Sons
                  </h4>
                  <p>
                    Web Design - Hosting - Domain - Email
                  </p>
                </div> 
              </a> 
            </div>

            

             <div class="tile">
              <a class="tile-inner" href="https://www.achimneysweep.co.uk/" target="_blank">
                <img src="@/img/clients/chimney_2.jpg" alt="A Chimney Sweep Logo">
                <div class="tile-text">
                  <h4>
                    A Chimney Sweep
                  </h4>
                  <p>
                    Web Design - Hosting - Domain - Email
                  </p>
                </div> 
              </a>
            </div>   

            <div class="tile">
              <a class="tile-inner" target="_blank">
                <img src="@/img/clients/Red.jpg" alt="Red Extinguishers Logo">
                <div class="tile-text">
                  <h4>
                    Red Extinguishers
                  </h4>
                  <p>
                    Web Design - Hosting 
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://settlementagreement-advice.co.uk/" target="_blank">
                <img src="@/img/clients/EmploymentLaw.jpg" alt="Settlement Agreement Logo">
                <div class="tile-text">
                  <h4>
                    Coleman Law
                  </h4>
                  <p>
                    Web Design - Hosting
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner">
                <img src="@/img/clients/Fragment.jpg" alt="Fragment Crafting Logo">
                <div class="tile-text">
                  <h4>
                    Fragment Crafting
                  </h4>
                  <p>
                    Web Design - Domain - Consultancy
                  </p>
                </div> 
              </a>
            </div>

            <div class="tile">
              <a class="tile-inner" href="https://www.wolstantonconservativeclub.co.uk/" target="_blank">
                <img src="@/img/clients/Wolstanton.jpg" alt="Wolstanton Conservative Club Logo">
                <div class="tile-text">
                  <h4>
                    Wolstanton Conservative Club
                  </h4>
                  <p>
                    Web Design - Hosting-Domain
                  </p>
                </div> 
              </a>
            </div>

            <!-- <div class="tile">
              <a class="tile-inner" href="https://www.razorfade.co.uk/#/" target="_blank">
                <img src="@/img/clients/AnZ.jpg" alt="Razorfade Logo">
                <div class="tile-text">
                  <h4>
                    A & Z Razorfade
                  </h4>
                  <p>
                    Web Design - Hosting - Domain - Email
                  </p>
                </div> 
              </a>
            </div> -->


          </div>

          </div>
        </Grid>
      </section>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';

  $main-color: #e15a2a;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;

  .client-page {

    @include breakpoint-small-laptop {
      .container {
        width: 90%;
      }
    }

    @include breakpoint-laptop {
      .container {
        width: 90%;
      }
    }
    @include breakpoint-large-laptop {
      .container {
        width: 100vw;
      }
    }
  }

  .our-clients-intro-container {
    margin-top: 4vw;
    padding-top: 6vw;
    background-image: url("../img/waves/Wave2c.svg");    
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;

    @include breakpoint-small-mobile {
      margin-top: 16vw;
    }

    @include breakpoint-mobile{
      margin-top: 14vw;
    }

    @include breakpoint-extra-large {
      background-size: cover;
      padding-top: 15vw;
    }

    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   margin-top: 11vw;
    // }

    .our-clients-intro-text-container {
      margin-left: auto;
      margin-right: auto;
      width: 55vw;
      padding-top: 10vw;

      @include breakpoint-small-mobile {
        width: 85vw;
        padding-top: 20vw;
      }

      @include breakpoint-mobile {
        width: 85vw;
        padding-top: 20vw;
      }

      @include breakpoint-tablet{
        width: 85vw;
        padding-top: 20vw;
      }

      @include breakpoint-extra-large {
        text-align: center;
      }
    }

    .our-clients-subtext {
      width: 55vw;
      color: #fc8e27;

      @include breakpoint-mobile {
        width: 85vw;
        margin-top: 5vw;
      }

      @include breakpoint-tablet{
        width: 85vw;
        margin-top: 5vw;
      }

      em {
        color: #e9e5e5;
        font-style: normal;
      }
    }

    .our-clients-subtext-inverted {
      width: 55vw;
      color: #e9e5e5;
    }
  }

  .our-clients-container {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 6vw;    
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;

    

    .our-clients-title {
      margin-top: 2vw;      
    }

    .our-clients-sub-title {
      color: #ffffff;
      margin-left: auto;
      margin-right: auto;
      width: 70vw;
    }

    .our-clients-body-text {
      padding: 2vw;
      margin-left: auto;
      margin-right: auto;
      width: 70vw;
    }

    h2 {
      color: #ffffff!important;
    }

    h3 {
      color: #ff7c00!important;

      em {
        color: #ffffff!important;
        font-style: normal;
      }
    }
  }

  .mobile-tile-title {
    margin-left: auto;
    margin-right: auto;
    color: $main-color;
    font-size: 2.5vw;
    line-height: 1;
    font-weight: bold;    
    font-style: "Montserrat", sans-serif;    
    display: block;
    position: relative;
    margin-bottom: 2vw;
    width: 55vw;
    text-align: center;

    @include breakpoint-mobile {
      font-size: 5vw;
      //width: 80vw;
      margin-bottom: 8vw;
      color: #fc8e27 !important;
      text-shadow: none !important;
    }

    @include breakpoint-tablet{
      font-size: 5vw;
      margin-bottom: 8vw;
      color: #fc8e27!important;
      text-shadow: none !important;
    }
    @include breakpoint-large-tablet{
      font-size: 5vw;
      margin-bottom: 8vw;
      color: #fc8e27!important;
      text-shadow: none !important;
    }

    @include breakpoint-large-laptop {
      color: white!important;
      text-shadow: 2px 2px rgb(0, 0, 0), 4px 4px #000;
    }
    
  }
  
  .tile-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    max-width: 1440px;
    margin: 0 auto;

    @include breakpoint-small-mobile {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }

    @include breakpoint-mobile {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }     
    
    @include breakpoint-tablet{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
    @include breakpoint-large-tablet{
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint-large-laptop {
      grid-template-columns: repeat(4, 1fr);
      max-width: 90vw;
    }

    .tile-inner {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;  
      border-radius: 15px;
      overflow: hidden;    
      cursor: pointer;
    }

    .tile img{
      width: 100%;
      height: 5rem;
      object-fit: fill;

      @include breakpoint-small-mobile {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        height: 4rem;
      }

      @include breakpoint-mobile {
        height: 22vw;
      }

      @include breakpoint-tablet{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        height: 20vw;
      }

      @include breakpoint-large-tablet{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        height: 20vw;
      }

      @include breakpoint-small-laptop {
        height: 13vw;
      }

      @include breakpoint-laptop {
        height: 13vw;
      }

      @include breakpoint-large-laptop {
        height: 10rem;
      } 
      
      @include breakpoint-desktop {
        height: 11vw;
      } 

      @include breakpoint-extra-large {
        height: 14rem;
      }
    }

    .tile-text {
      width: 100%;
      text-align: center;
      padding: 0.7rem;
      background: linear-gradient(to bottom left, #fc481c, #c9bc43);
      min-height: 7rem;
      max-height: 7rem;

      @include breakpoint-large-tablet {
        min-height: 8rem;
        max-height: 8rem;
      }

      @include breakpoint-extra-large {
        min-height: 8rem;
        max-height: 8rem;
      }

      h1,h2,h3,h4,h5,h6,p {
        margin: unset!important;
        color: #ffffff;

        @include breakpoint-large-tablet {
          font-size: 1.4rem;
        }
      }

      p {
        display: -webkit-box;
        font-size: 0.8rem;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include breakpoint-large-tablet {
          font-size: 1rem;
        }

        @include breakpoint-laptop {
          font-size: 1.1rem;
        }

        @include breakpoint-large-laptop {
          font-size: 1rem;
        }

        @include breakpoint-desktop {
          font-size: 1rem;
        }

        @include breakpoint-extra-large {
          font-size: 1.2rem;
        }
      }
    }

    .tile:nth-child(1){
        grid-column: span 1;
        grid-row: span 1;
    }

    .tile:nth-child(2),
    .tile:nth-child(3){
        grid-column: span 1;
    }

    .tile:nth-child(4),
    .tile:nth-child(5){
        grid-column: span 1;
    }    
    .tile:nth-child(6){
        grid-column: span 1;
        grid-row: span 1;
    }
    .tile:nth-child(7),
    .tile:nth-child(8){
        grid-column: span 1;
    }
    @media screen and (max-width: 650px){
      .container{
          display: block;
      }
    }
  }

  .client-container {
    background-color: $secondary-color;
    height: 100vh;
  }

  .modal {
    z-index: 30;
    display: none;
    margin-top: 4vw;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 94vh;
    overflow: hidden;
    background-color: #000000f0;
    border-top: 0.5vw solid #ff7a00;
    outline: 0;

    .modal-image {
      position: absolute;
      height: 15vw!important;
      width: 25vw!important;
    }

    .modal-image.one {
      top: 0;
      left: 10vw;
      z-index: 2;
    }

    .modal-image.two {
      top: 10vw;
      left: 0vw;
      z-index: 1;
    }

    .modal-image.three {
      bottom: 0;
      right: 5vw;
      z-index: 3;
    }

    .modal-image.four {
      bottom: 10vw;
      right: 0vw;
      z-index: 2;
    }    

    .modal-text-container {
      position: relative;
      display: block;
      z-index: 3;
      margin: 5vw auto;
      padding: 2vw;
      width: 30vw;
      background: #ff9100cf;
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 6.0px );
      -webkit-backdrop-filter: blur( 6.0px );
      border-radius: 10px;
      text-align: center;
      h4 {
        color: #ffffff;
      }
    }

    .modal-content {
      margin: auto;
      background-color: #fff0;
      position: relative;
      padding: 0;
      outline: 0;
      width: 600px;
      -webkit-animation: animatezoom 0.6s;
      animation: animatezoom 0.6s;
      box-shadow: 0 5px 15px #00000000 !important;
      border: 1px solid #00000000 !important;

      .tile-container{
        padding: 0;
        margin: 0;
        justify-content: center;
        
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        .masonry {
          columns: 6;
          -moz-column-gap: 1em;
          column-gap: 1em;
          // @include media($breakpoint-below-laptop) {
          //   columns: 2;   
          // }

          .slate{
            width: 14vw;
            max-width: 14vw;
            height: auto;
            color: white;
            font-weight: bold;
            text-align: center;
            overflow: hidden;
            transition: background-color 0.5s ease, transform 0.5s ease;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            
            img{
              width: 100%;
              display: block;
              transition: transform 3s ease;
            }
            a{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              // padding: 1em;
              display: flex;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              flex-flow: column wrap;
              text-align: center;
              color: white;
              
            }
            
          }
        }
      }
    }

    .close-button-container {
      height: 3em;
      width: 3em;
      position: absolute;
      right: 1vw;
      top: 1vw;

      .modal-close-button {
        cursor: pointer;
        width: 2em;
        height: 2em;
        padding: 5px 5px;
        border-radius: 50%;
        border-color: #ffffff;
        border-style: solid;
        transition: border-color 1s ease;
      }

      .modal-close-button:hover {
        border-color: #f98e00;
      }
    }
  }  
</style>



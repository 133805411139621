@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="WhatWeDo"
    itemscope
    itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->  
    <main class="main-wrap">
      <div class="content-wrap">
        <div class="what-we-do-intro-container">
          <div class="what-we-do-intro-text-container">
            <h1>High quality service,<br> by looking after your business like our own.</h1>
            <h3 class="wwd-subtext"> We don't just build great websites and software, we also provide <em>Web Hosting</em>, <em>Domain Names</em> and 
            <em>Business Emails</em>, either just one on their own or as a complete package.</h3>
            <h3 class="wwd-subtext-inverted">
              Talk to us today about how we can help manage everything for you and your business.
            </h3>
          </div>
        </div>

        <!-- Web Hosting Section -->
        <div class="what-we-do-container hosting">
          <Section class="pt2 what-we-do-grid">
            <div class="services-cont">
              <div class="service-inverted what-we-do">
                <div class="center">
                  <h2 class="what-we-do-title">
                    Web Hosting
                  </h2>
                  <h4 class="what-we-do-sub-title">
                    Having your web hosting setup correctly is crucial, especially when your business relies heavily on your customers seeing your website in order to make sales.
                  </h4>

                  <div class="wwd-grid-container wwd-grid-one ">
                    <div class="wwd-grid-image-container">
                      <img class="wwd-grid-image" src="@/img/services/hosting.jpg" alt="An image of hosting servers for website hosting">
                    </div>
                    <div class="wwd-grid-text-container web-services">
                      <p>
                        Unfortunately, web hosting is often the least thought about aspect when it comes to developing a website. Which is why thousands of websites go down every year, costing businesses large amounts of lost sales, due to customers going to a competitors website that is still live. 
                        <br><br>
                        As part of our work with clients, we plan ahead and make sure that the right hosting is going to be used, so that our clients only pay for what they need.
                      </p>
                      
                    </div>
                  </div>

                  <h3 class="what-we-do-contact-body-text"> 
                    To make sure your website has the right hosting, speak to us <em>today</em>.              
                  </h3>
                  <div>
                    <router-link to="Contact" class="contact-button-container">
                      <div class="pulse-button" >Contact Us</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>

        <!-- Domain Names Section -->
        <div class="what-we-do-container domain">
          <Section class="pt2 what-we-do-grid">
            <div class="services-cont">

              <!-- Orb 1 -->
              <svg class="host-orb colour one" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="host1" x1="25%" y1="100%" x2="50%" y2="0%">
                    <stop offset="0%" style="stop-color:#ff4b00ff;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#ffcf00;stop-opacity:1" />
                  </linearGradient>
                </defs>
                <path fill="url(#host1)" d="M69.4,-22.2C78.9,6.7,67.9,42.5,43.8,59.9C19.8,77.2,-17.4,76.2,-41.1,58.5C-64.8,40.8,-75,6.6,-65.9,-21.8C-56.8,-50.1,-28.4,-72.6,0.8,-72.8C29.9,-73.1,59.9,-51.1,69.4,-22.2Z" transform="translate(100 100)" />
              </svg>

              <!-- Orb 2 -->
              <svg class="host-orb colour two" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="host2" x1="25%" y1="100%" x2="50%" y2="0%">
                    <stop offset="0%" style="stop-color:#ff4b00ff;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#ffcf00;stop-opacity:1" />
                  </linearGradient>
                </defs>
                <path fill="url(#host2)" d="M69.4,-22.2C78.9,6.7,67.9,42.5,43.8,59.9C19.8,77.2,-17.4,76.2,-41.1,58.5C-64.8,40.8,-75,6.6,-65.9,-21.8C-56.8,-50.1,-28.4,-72.6,0.8,-72.8C29.9,-73.1,59.9,-51.1,69.4,-22.2Z" transform="translate(100 100)" />
              </svg>

              <!-- Orb 3 -->
              <svg class="host-orb colour three" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="host3" x1="25%" y1="100%" x2="50%" y2="0%">
                    <stop offset="0%" style="stop-color:#ff4b00ff;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#ffcf00;stop-opacity:1" />
                  </linearGradient>
                </defs>
                <path fill="url(#host3)" d="M69.4,-22.2C78.9,6.7,67.9,42.5,43.8,59.9C19.8,77.2,-17.4,76.2,-41.1,58.5C-64.8,40.8,-75,6.6,-65.9,-21.8C-56.8,-50.1,-28.4,-72.6,0.8,-72.8C29.9,-73.1,59.9,-51.1,69.4,-22.2Z" transform="translate(100 100)" />
              </svg>

              <div class="service-inverted what-we-do">
                <div class="center">
                  <h2 class="what-we-do-title">
                    Domain Names
                  </h2>
                  <h4 class="what-we-do-sub-title">
                    When it comes to Domain names, many business owners think its just their business name followed by .co.uk for example. But it is so much more than that, especially if your business name is particularly long or complex. But we can help to manage all of that for you.
                  </h4>
                  <p class="what-we-do-body-text">
                    Domain names are the gateway to your website, and depending on how your marketing and web strategy is planned out, they can require either a simple setup right the way through to a fully mapped out Domain Strategy that provides SEO benefits.
                  </p>              
                  <h3 class="what-we-do-contact-body-text"> 
                    If you need help with your domain name, or wish to have a no-jargon discussion about how we can help unlock the power of domain names for you business, then contact us <em>today</em>.              
                  </h3>
                  <div>
                    <router-link to="Contact" class="contact-button-container">
                      <div class="pulse-button" >Contact Us</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>

        <!-- Emails Section -->
        <div class="what-we-do-container business-emails">
          <Section class="pt2 what-we-do-grid">
            <div class="services-cont">
              <div class="service-inverted what-we-do">
                <div class="center">
                  <h2 class="what-we-do-title">
                    Business Emails
                  </h2>
                  <h4 class="what-we-do-sub-title">
                    By using Microsoft's Outlook Business Email exchange for your business, you can have peace of mind that you can communicate with your customers day or night, 365 days a year.
                  </h4>
                  <div class="wwd-grid-container wwd-grid-one ">                    
                    <div class="wwd-grid-text-container web-services inverted">
                      <p>
                        Having a business email address is one of the main things that customers expect to see when engaging with any business. By having emails for your business you not only look professional, but it also allows a consistent way for your customers to contact the right person in the business, as well as giving you access to emails on the go.
                        <br><br>
                        By being a business owner you will appreciate the value it brings by having your business emails provided by Microsoft, which is one of the largest software companies in the world. By leveraging the Outlook email exchange you can rest assured that your emails will be secure, reliably send and receive emails, as well as being easily scalable so that as your business grows so does your email exchange.
                      </p>                      
                    </div>
                    <div class="wwd-grid-image-container">
                      <img class="wwd-grid-image" src="@/img/services/email.png" alt="Microsoft Outlook logo">
                    </div>
                  </div>

                  <h3 class="what-we-do-contact-body-text"> 
                    If you would like to discuss your business email options, speak to us <em>today</em>.              
                  </h3>
                  <div>
                    <router-link to="Contact" class="contact-button-container">
                      <div class="pulse-button" >Contact Us</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>

        <ClientSlider/>


      </div>
    </main>
  </body>
</template>

<script>
import ClientSlider from "@/components/ClientSlider.vue";
export default {
  components: {
    ClientSlider,
  }
} 
</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';

  $main-color: #e15a2a;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;
  $quarteriary-color: #eea424;

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }
</style>
<template>
<div class="services-background-container">
  <div class="services-container">
    <div class="services-text-container">
      <h1>How we help <br> our clients</h1>
      <p>
        Our dedicated team of four has over <em>24 years industry experience</em> across a wide range of software development.
        By taking the time to get to know each of our client's needs, we are able to apply our knowledge in the right way that best supports our clients and add greater value to the relationship.        
       </p>
       <h3 class="CTA">Click a section of the CyberKiln to find out more.</h3>
    </div>

    <div class="services-bottle-kiln-container">
      <!-- Bottom-Left App Development -->
      <router-link to="Consultancy">
        <div class="kiln-service service1"></div>
      </router-link>
      <router-link to="Services">    
        <div class="kiln-service service2"></div>
      </router-link>
      <router-link to="Websites">
        <div class="kiln-service service3"></div>
      </router-link>
      <router-link to="Software">
        <div class="kiln-service service4"></div>
      </router-link>
    </div>
  </div>
</div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">

</style>
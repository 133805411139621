<template>  
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="contact"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
    
    <main class="main-wrap">
      <div class="content-wrap">
        <ContactForm />
      </div>
    </main>
  </body>
</template>

<script>
import modal from "@/components/ContactModal.vue";
import ContactForm from "@/components/ContactForm.vue";

export default{  
 name: "Contact",
  components: {
    modal, // eslint-disable-line vue/no-unused-components
    ContactForm
  },
  metaInfo() {
    return { 
      title: "CyberKiln - Contact",
      meta: [
        { name: 'description', content:  'The home of dedicated bespoke web & software specialists based in Stoke-on-Trent.'},
        { property: 'og:site_name', content: 'CyberKiln - Contact'},
        {property: 'og:type', content: 'website'},
        { property: 'og:title', content: "CyberKiln - Contact"},
        {property: 'og:url', content: 'https://www.cyberkiln.com/Contact'}, 
        {property: 'og:description', content: 'The home of dedicated bespoke web & software specialists based in Stoke-on-Trent.'}, 
        {property: 'og:image', content: 'https://cyberkiln.com/img/metaimages/CyberKiln.jpg'}, 
        {property: 'image', content: 'https://cyberkiln.com/img/metaimages/CyberKiln.jpg'}, 
        {property: 'og:image:type', content: 'image/jpg'}, 
        {property: 'og:image:width', content: '800'}, 
        {property: 'og:image:height', content: '438'},
      ]
    }
  }, 
  data() {
    return {
      isModalVisible: false
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },  
};

</script>

<style lang="scss">
</style>
<template>
  <div>
  <!-- Footer -->
    <footer
      class="footer"
      itemtype="http://schema.org/WPFooter"
      itemscope
    >
      <!-- 1st Footer Row -->
      <div class="container">
        <div class="footer__widgets">
          <div class="row footer-contact-grid-container">
            <!-- 1st Column contact -->
            <div class="col-lg-4 col-md-6">
              <div class="widget widget-about-us text-left text-sm-center text-lg-center">
                <span class="widget-about-us__text"
                  >We’d love to hear from you</span
                >
                <a
                  href="mailto:chris@cyberkiln.com"
                  class="widget-about-us__email"
                  >Email</a
                >
                <a href="tel:01782914914" class="widget-about-us__phone"
                  >Call</a
                >
                <a
                  href="https://maps.app.goo.gl/dn5xf1R2bhRmfgS27" target="_blank"
                  class="widget-about-us__email"
                  >Our Office at Cauldon Locks</a
                >
                <div class="socials mt-32">
                  <a
                    href="https://www.instagram.com/cyberkiln/"
                    class="social social-instagram"
                    aria-label="instagram"
                    title="instagram"
                    target="_blank"
                  >
                    <i class="ui-instagram"></i>
                  </a>
                  <a
                    href="https://uk.linkedin.com/company/cyberkiln"
                    class="social social-google-plus"
                    aria-label="linkedin"
                    title="linkedin"
                    target="_blank"
                  >
                    <i class="ui-linkedin"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/CyberKiln"
                    class="social social-facebook"
                    aria-label="facebook"
                    title="facebook"
                    target="_blank"
                  >
                    <i class="ui-facebook"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- 2nd Column CK Logo -->
            <div class="col-lg-4 col-md-6 text-center">
              <img class="ck-logo"
                src="@/img/branding/header-logo.svg"
                alt="CyberKiln Logo"
              />
              <div class="widget text-center">
                <h3 class="widget-cta__title white mb-32">
                  Crafting your digital world
                </h3>
              </div>
            </div>
            <!--3rd Column Networking Image Row -->
            <div class="row networking-grid-container col-lg-4 col-md-6">
              <div class="footer-logo-container">
                <a href="https://bnistaffordshire.co.uk/staffordshire-bni-victory/en-GB/memberlist?chapterName=29681&regionIds=9057$isChapterwebsite" target="_blank">
                  <img class="footer-logo" src="@/img/foot4.png" alt="BNI business network Logo">
                </a>
              </div>
              <div class="footer-logo-container">
                <a href="https://staffordshirechambers.co.uk/" target="_blank">
                  <img class="footer-logo" src="@/img/SCC.png" alt="Staffordshire Chamber of Commerce Logo">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 2nd Footer Row -->
      <div class="footer__bottom">
        <div class="container" style="margin-bottom: 0px;">
          <div class="copyright-wrap text-center row">
            <div class="col-lg-4 col-md-6 text-center">
              <span class="copyright">
                <router-link to="Privacy" class="copyright">
                  <span>Privacy Policy</span>
                </router-link>
              </span>
            </div>
            <div class="col-lg-4 col-md-6 text-center">
              <span class="copyright">
                CyberKiln is a trading name of Cyber Kiln Ltd. <br>
                Registered in England No. 12034623.
              </span>
            </div>
            <!-- <div class="col-lg-4 col-md-6 text-center">
              <span class="copyright">
                <router-link to="Terms" class="copyright">
                  <span>Terms & Conditions</span>
                </router-link>
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </footer>
    <!-- end footer -->
    <!-- <div class="footer-placeholder" id="contact"></div> -->
  </div>
</template>

<script>
import modal from "@/components/ContactModal.vue";

export default {
  name: "app",
  components: {
    modal // eslint-disable-line vue/no-unused-components
  },
  data() {
    return {
      isModalVisible: false,
      privacyPolicy: "/documents/HS Privacy Policy.pdf"
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style lang="scss"> 
@import '../styles/base/_mixins.scss';

.footer {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0vw;
  background-image: url("../img/waves/Wave3.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  height: -webkit-fill-available;
  height: -moz-fill-available;
  height: -fill-available;
  background-color: unset;

  @include breakpoint-small-mobile {
    background-image: url("../img/waves/Wave3-small-mobile.svg");
  }

  @include breakpoint-laptop {
    background-position: left bottom;
    background-size: 120%;
  }

  @include breakpoint-large-laptop {
    background-position: left bottom;
    background-size: 120%;
  }

  @include breakpoint-desktop {
    background-position: left bottom;
    background-size: 120%;
  }
}

.footer-left-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  left: 0;
  height: 50vw;
  z-index: -3;
}

.footer-right-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  right: 0;
  height: 50vw;
  z-index: -3;
}

.ck-logo {
  width: 26vw;
  height: 6vw;

  @include breakpoint-small-mobile {
    width: 52vw;
    height: 12vw;
  }

  @include breakpoint-mobile{
    width: 52vw;
    height: 12vw;
  }
}

.footer-contact-grid-container{
  @include breakpoint-large-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
  }

  @include breakpoint-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
  }

}

.networking-grid-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 30em;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: 0.5em;
  gap: 5rem;
  align-items: center;

  @include breakpoint-small-mobile{
    justify-content: center;
  }

  @include breakpoint-large-tablet{
    column-count: 2 !important;
    display: table-row !important;
    gap: 1rem;
  }

  @include breakpoint-tablet{
    column-count: 2 !important;
    display: table-row !important;
  }

  @include breakpoint-mobile {
    column-count: 2 !important;
    display: table-row !important;
  }
}

.footer-logo-container {
  flex: 1 0 40%;
  max-width: 100%;
  @include breakpoint-small-mobile {
    flex: 1 1 40%;
    padding: 0vw;
    max-width: 50%;
  }
  @include breakpoint-mobile {
    flex: 1 1 20%;
    padding: 0vw;
  }
  @include breakpoint-tablet {
    flex: 1 1 10%;
    padding: 0em;
  }
  @include breakpoint-large-tablet {
    flex: 1 1 10%;
    padding: 0em;
  }
  @include breakpoint-small-laptop  {
    flex: 1 0 100%;
  }
}

.footer-logo {
  width: 10vw;
  cursor: pointer;
  height: auto;
  margin-left: 5rem;

  @include breakpoint-small-mobile {
    padding: 0em;
    width: 50vw;
    margin-left: 0rem;
  }
  @include breakpoint-mobile {
    padding: 0em;
    width: 27vw;
    margin-left: 0rem;
  }
  @include breakpoint-tablet {
    padding: 0.4em;
    width: 24vw;
    margin-left: 0rem;
  }
  @include breakpoint-large-tablet {
    padding: 0.4em;
    width: 24vw;
    margin-left: 0rem;
  }
  @include breakpoint-small-laptop  {
    width: 10rem;
    margin-left: 0rem;
  }
}

.footer__bottom {
  z-index: 5;
  padding-bottom: 8em;
  margin-top: 2vw;

  @include breakpoint-small-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }
  @include breakpoint-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint-tablet {
    padding-bottom: 2em;
  }

  @include breakpoint-large-tablet {
    padding-bottom: 0;
    margin-top: 0;
  }
}

</style>
<template>
  <div class="step-process-block">
    <div class="step-process-cont">
      <h2 class="what-we-do-title" style="text-align: center;">
        Simple and easy approach
      </h2>
      <p class="step-process-sub-title" style="text-align: center;">
        Our step-by-step process is set out so we can capture as much vital information, confirm all of the key requirements and deliver the required results. Our process enables us to maximise customer satisfaction and ensure we deliver what is right for you.
        <br/>
      </p>

      <div class="step-process">
        <details>
          <summary>Step 1 - Fact finding and project planning</summary>
          <p>
            <b>A free exploration meeting.</b> This is where we get to know each other and create a list of the key requirements for the project. 
          </p>
          <p>
            <b>A structured project proposal.</b> After the meeting, we define your requirements and then provide a list of options where each option provides you with estimated time frame and the total cost. We do this so there is no hidden fees and it allows you to decide what is right for your business and budget.
          </p>
          <p>
            <b>A detailed contract.</b> Once you let us know which option you would like, we will then send a detailed contract which lays out all of the key elements of the project development, the timescales, milestone meetings and payment schedules. Once we receive a signed contract, we will confirm when we are scheduled to start your project and then it is onto the next step.
          </p>
        </details>
        <details>
          <summary>Step 2 - Design, Develop, Test and Launch</summary>
          <p>
            <b>Design, develop and test</b> When we start the project, we go through the process of creating the strategic design, also known as the wireframe, so you can get a feel for what the end result will look like. Once the design is signed off, we then get underway with coding your project. We work by milestones and so based on your contract, we will have regular milestone meetings to demo our progress, and to discuss anything that you wish about the project. Once we have completed making the code we then rigorously test it using multiple devices to make sure it is looks and performs at its best. 
          </p>
          <p>
            <b>Sign-off meeting</b> Before we complete the project we meet with our clients to make sure it meets all the requirements and that our customers are happy with what we have made. This gives our clients the confidence that everything is as it should be before it is made public or implemented into their business.
          </p>
          <p>
            <b>Launch time</b> Once the project is signed off and the client is happy we then make it LIVE. Whether this be websites being hosted on the web, apps on both the Apple and Google stores or software on business servers we take care of the launch process from start to finish.
          </p>
        </details>
        <details>
          <summary>Step 3 - Support and future proofing</summary>
          <p>
            <b>After market support</b> Once we have launched the project for our client, we don't just shake hands and say goodbye, we like to stay connected to ensure everything is running smoothly. We do this, not only because we feel it is the right thing to do, but also because we appreciate the time we spend with our clients, and our clients value having us on hand to handle any issues that may affect their business.
          </p>
          <p>
            <b>Future development and business planning</b> Naturally, as time moves on the world of technology changes too, and so by us keeping in regular contact with our clients we help to keep them updated, in a very clear no-jargon way, about key developments in technology, and how we can leverage these changes to help their business, and more importantly add value for their customers.
          </p>
        </details>      
      </div>
      <p style="text-align: center;">
        By following these steps we nurture a long-lasting relationship that enhances both businesses and enables us to keep supporting our clients year after year.
      </p>             

      <h3 class="steps-contact-body-text" > 
        If you want to speak to a software company that doesn't baffle you with jargon, and is able to provide the right solution for your business, then speak to us today.             
      </h3>
      <div style="text-align: center;">
        <router-link to="Contact" class="contact-button-container">
          <div class="pulse-button" >Contact Us</div>
        </router-link>
      </div>    
    </div>
  </div>
</template>




<script>
export default ({
  mounted() {
  }
})
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.step-process-block {
  width: 100vw;
  background-image: url("@/img/waves/Wave6c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 1vw 0vw 5vw 0vw;

  @include breakpoint-small-mobile {
  background-image: url("@/img/waves/Wave6c-small-mobile.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 25vw;
    padding-bottom: 10vw;
  }

  @include breakpoint-mobile {
    background-image: url("@/img/waves/Wave6c-mobile.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include breakpoint-tablet {
    background-image: url("@/img/waves/Wave6c-tablet.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20vw;
    padding-bottom: 15vw;
  }

  @include breakpoint-large-tablet {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5vw;
    padding-bottom: 5vw;
  }

  @include breakpoint-laptop {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5vw;
    padding-bottom: 5vw;
  }

  @include breakpoint-large-laptop {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5vw;
    padding-bottom: 5vw;
  }

  @include breakpoint-desktop {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5vw;
    padding-bottom: 5vw;
  }

  @include breakpoint-extra-large {
    background-size: cover;
    height: 140vh;
  }
}

.step-process-cont {
  display: block;
  position: relative;
  margin: 5vw auto;
  width: 70vw;
  padding: 2em 4em;
  border-radius: 25px;
  background-color: #081c2fed;  

  @include breakpoint-small-mobile {
    width: 95vw;
    padding: 1em 1em 1em 1em;
  }

  @include breakpoint-mobile {
    width: 95vw;
    padding: 1em 1em 1em 1em;
  }

  @include breakpoint-tablet {
    width: 85vw;
    padding: 2em 5vw;
  }

  @include breakpoint-large-tablet {
    width: 85vw;
    padding: 2em 5vw;
  }

  @include breakpoint-small-laptop {
    width: 80vw;
  }

  @include breakpoint-laptop {
    width: 80vw;
  }

  @include breakpoint-extra-large {
    margin: 10vw auto;
  }
}

.step-process {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
}

.step-process-sub-title {
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vw;
}

.steps-contact-body-text {
  color: #ff7c00 !important;
  font-size: 1.5rem;
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

details {
  width: 50vw;
  background: linear-gradient(144deg, #f4573d, #ddc214);
  padding: 1em;
  border-radius: 0.5em;
  margin: 1.2em;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 10px 12px 12px 0px #ffdf548a;

  @include breakpoint-small-mobile {
    width: 80vw;
    box-shadow: 4px 8px 12px 0px rgb(255 223 84 / 54%);
  }

  @include breakpoint-mobile {
    width: 80vw;
    box-shadow: 4px 8px 12px 0px rgb(255 223 84 / 54%);
  }

  @include breakpoint-tablet {
    width: 70vw;
  }

  @include breakpoint-large-tablet {
    width: 70vw;
  }

  @include breakpoint-small-laptop {
    width: inherit;
  }

  @include breakpoint-laptop {
    width: inherit;
  }
}
details[open] > summary::after {
  content: "⯅";
}

:focus {
  outline: none;
}
summary {
  list-style: none;
  position: relative;
  text-align: center;
  font-weight: bold;
}
summary::after {
  position: absolute;
  content: "⯆";
  right: 0;
  
}
details > p {
  cursor: text;
  margin-top: 0.2em;
  width: fit-content;
  padding: 0.2em 1em;
  border-radius: 0.2em;
  
  b {
    color: #5f634f;
  }  
}
</style>
<template>
  <!-- Contact Form -->
  <div
    class="modal fade contact-form-modal"
    id="contact-form-modal"
    role="dialog"
    aria-label="Contact Form Modal"
    aria-hidden="true"
    aria-labelledby="ContactModal"
    aria-describedby="ContactModal"
  >
    <div class="modal-dialog contact-form-modal__dialog" role="document">
      <div class="modal-content contact-form-modal__content bg-dark bg-pattern">
        <button
          type="button"
          class="close contact-form-modal__close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>

        <div class="modal-body contact-form-modal__body">
          <form id="contact-form" class="contact-form" action="#">
            <div class="row row-16">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="name">
                    Name
                    <abbr>*</abbr>
                  </label>
                  <input type="text" name="NAME" id="name" required />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="email">
                    Email
                    <abbr>*</abbr>
                  </label>
                  <input type="email" name="EMAIL" id="email" required />
                </div>
              </div>
            </div>

            <label for="message">
              Message
              <abbr>*</abbr>
            </label>
            <textarea name="MESSAGE" id="message" rows="6"></textarea>
            <div class="gdpr-checkbox">
              <label class="gdpr-checkbox__label">
                <input
                  class="gdpr-checkbox__input"
                  name="AGREE_TO_TERMS"
                  type="checkbox"
                  value="1"
                  required
                />
                <span class="gdpr-checkbox__text">
                  I agree to storage of my email according to the Privacy Policy
                  <!-- <router-link to="Privacy" target="_blank">Privacy Policy</router-link> -->
                </span>
              </label>
            </div>
            <div class="text-center">
              <input
                id="contact-form__submit"
                type="submit"
                class="btn btn--lg btn--color"
                value="Send Message"
              />
            </div>

            <div
              id="contact-form__message"
              class="contact-form__message"
              role="alert"
            ></div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end contact form -->
</template>

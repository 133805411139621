@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="WhatWeDo"
    itemscope
    itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->

    <main class="main-wrap">
      <div class="content-wrap">
        <div class="what-we-do-intro-container">
          <div class="what-we-do-intro-text-container">
            <h1>
              Bespoke Software Consultancy
            </h1>
            <h3 class="wwd-subtext">
              Helping businesses and business owners to get the best out of technology is what comes naturally to us here at CyberKiln, that's why we work as digital transformation consultants to businesses across the UK.              
            </h3>
            <h3 class="wwd-subtext-inverted">
              Whether you are a small business looking to implement software systems, or a large business that has a complicated array of software solutions and you need help to navigate the impact that it will have on your business, we can help you to get the best possible results.
            </h3>
          </div>
        </div>

        <!-- Consultancy Services Section -->
        <div class="what-we-do-container consulting">
          <div class="services-cont">
            <div class="service-inverted what-we-do consulting">
              <h1 class="what-we-do-title">
                Consultancy Services
              </h1>
              <h4 class="what-we-do-sub-title">
                We know that every business faces its own unique digital challenges, and that as the world of technology is constantly evolving, it is easy for the knowledge and understanding of technology that businesses to quickly become outdated. That is where you can utilise our knowledge and experience in your business by having CyberKiln on hand to provide consultancy to business owners and managers.
              </h4> 
              <section class="panel-grid-layout row panel-grid-layout--overlap u-align-left u-overflow-hidden">            
                <div class="panel-grid-container">
                  <div class="panel-grid-wrapper">                
                    <div class="panel-grid consultation panel-grid--left col-wrapper flex-wrapper col-wrapper--seamless">
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">
                            <h3 class="panel-title">Digital Strategy</h3>
                            <p class="panel-excerpt">Learning about the business and the direction that stakeholders want to take the business, and then planning a route on how best to implement the new software with minimal disruption to the business.</p>
                          </div>
                        </div>	      	
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">
                            <h3 class="panel-title">Digital Innovation</h3>
                            <p class="panel-excerpt">Developing a new product or service in your business by identifying current and future technology, mapping out how to create a minimum viable product (MVP), and then getting it to market.</p>
                          </div>
                        </div>	      	
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">
                            <h3 class="panel-title">Business Automation</h3>
                            <p class="panel-excerpt">Identify key areas of the business that can be streamlined and automated by the use of software, which will release the human hours of your staff so that can be better spent adding value where it really matters.</p>
                          </div>
                        </div>	      	
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">
                            <h3 class="panel-title">Team Development</h3>
                            <p class="panel-excerpt">Assess current digital skill levels of your staff, and then provide training strategies, to unlock their potential and help improve knowledge and confidence to use the software in your business.</p>
                          </div>
                        </div>	      	
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">
                            <h3 class="panel-title">Digital Modernisation</h3>
                            <p class="panel-excerpt">Review current software used within your business, and then provide recommendations for software that provides better value and benefits to your business.</p>
                          </div>
                        </div>
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">
                            <h3 class="panel-title">Technical Directorship</h3>
                            <p class="panel-excerpt">Supporting your business with technical guidance and insights at director level, but without the hassle and funding needed to have a full-time technical director.</p>
                          </div>
                        </div>	      	
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">                
                            <h3 class="panel-title">Code Review</h3>
                            <p class="panel-excerpt">Review existing software that you are looking to improve, or oversee and review new software that you are currently developing.</p>
                          </div>
                        </div>	      	
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link">
                          <div class="panel-body">
                            <h3 class="panel-title">Project Management</h3>
                            <p class="panel-excerpt">Review current, and implement new if needed, project management processes to keep delivery on time and on budget.</p>
                          </div>
                        </div>
                      </div>
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link ">
                          <div class="panel-body">
                            <h3 class="panel-title">Software Takeover</h3>
                            <p class="panel-excerpt">Investigate the your new software progress and what the previous developer has created so far, and then we can advise on how to develop it further.</p>
                          </div>
                        </div>	      	
                      </div>              
                      <div class="panel-grid-item col--18">
                        <div class="panel  panel--link ">
                          <div class="panel-body">
                            <h3 class="panel-title">Software Design</h3>
                            <p class="panel-excerpt">Collaborate with your business to provide insight into how to make your software and apps not only look great, but to also function at its best as well.</p>
                          </div>
                        </div>	      	
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <!-- Benefits of CyberKiln Consultancy -->

        <!-- Flexibility
As your organisation changes, so will your IT needs.  An in-house team may not have all of the necessary skills to adopt new technologies which can distract from your core objectives.

Managed services not only free up your employees to focus on the jobs they were hired to do — it also enables you to fill in gaps as needed. Stone offer flexible service models to ensure we offer the right packages to meet your organisation needs.  Anything from acting as a 3rd line support partner through to outsourcing your organisations entire IT department.

Expertise
You have a dedicated team of specialists who take care of your IT requirements without needing to tap into your own workforce. With managed services, you get robust and resourceful teams  supporting your users, and none of the hassle or stress of hiring internally.

Service management and continuous improvement ensures that the service that is provided continues to evolve as your organisation changes.

Resource
Continuity of service is of paramount importance to any organisation.  Through our Stone assist service, the worry of staff absence is removed from your concerns as we have the strength in depth to support your organisation. -->

        <StepProcess/>
        <ClientSlider/>
      </div>
    </main>
  </body>
</template>

<script>
import StepProcess from "@/components/StepProcess.vue";
import ClientSlider from "@/components/ClientSlider.vue";
export default {
  components: {
    ClientSlider,
    StepProcess,
  }
}
</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';

  $main-color: #e15a2a;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;
  $quarteriary-color: #eea424;

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }
</style>
<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="t4w"
    itemscope
    itemtype="http://schema.org/WebPage"
  >
  <!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->
  <main class="main-wrap">
      <div class="content-wrap">
        <section class="T4W">
          <h2 class="text-center">Sunshine Gym App Privacy Policy</h2>
          <br/>
          <p>
            Sunshine Gym (http://www.sunshinegym.co.uk/) part of Broxap Ltd (“we”) are committed to protecting and respecting your privacy.
            <br/><br/>
            This policy (together with our terms of use and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.
            <br/><br/>
            For the purpose of the General Data Protection Regulation Act 2016, the data controller is Broxap Limited (Company number 02583752) whose registered office is at Broxap Ltd., Rowhurst Industrial Estate, Chesterton, Newcastle-under-Lyme, Staffordshire, ST5 6BD.
            <br/><br/>
            INFORMATION WE MAY COLLECT FROM YOU
            <br/><br/>
            We may collect and process the following data about you:
            <br/>
            Information or material that you provide or input on our site http://www.broxap.com/ (our site). We may also ask you for information when you report a problem with our site.
            If you contact us, we may keep a record of that correspondence.
            We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them.
            Details of transactions you carry out through our site and of the fulfilment of your orders.
            Details of your visits to our site including, but not limited to, traffic data, location data, web logs and other communication data, whether this is required for our own billing purposes or otherwise and the resources that you access.
            Contact details; that is, who you are, where you work, and how to contact you.
            <br/><br/>
            WHERE WE STORE YOUR PERSONAL DATA
            <br/><br/>
            The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.
            <br/><br/>
            All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using secure socket layer (SSL) technology. The encryption process converts the characters that you enter into code that is then securely transmitted over the Internet. When you place an order, a small padlock will appear at the bottom of your browser indicating that any information provided cannot be viewed by a third party. Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.
            <br/><br/>
            USES MADE OF THE INFORMATION
            <br/><br/>
            We use information held about you in the following ways:
            <br/>
            To ensure that content from our site is presented in the most effective manner for you and for your computer.
            To provide you with information, products or services that you request from us.<br/><br/>
            To provide you with information which we feel may interest you, where you have consented to be contacted for such purposes.
            To carry out our obligations arising from any contracts entered into between you and us.<br/><br/>
            To allow you to participate in interactive features of our service, when you choose to do so.<br/><br/>
            To notify you about changes to our service.<br/><br/>
            We may also use your data, or permit selected third parties to use your data, to provide you with information about goods and services which may be of interest to you and we or they may contact you about these by post. If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale to you or where you have consented. If you are a new customer, and where we permit selected third parties to use your data, we (or they) will contact you by electronic means only if you have consented to this. If you do not want us to use your data in this way, or to pass your details on to third parties for marketing purposes, please tick the relevant box situated on the form on which we collect your data (the order form).
            <br/><br/>
            DISCLOSURE OF YOUR INFORMATION
            <br/><br/>
            We may disclose your personal information to any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 736 of the UK Companies Act 1985. We may disclose your personal information to third parties:
            <br/><br/>
            In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.<br/><br/>
            If Broxap Limited or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.
            If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use or terms and conditions of supply and other agreements; or to protect the rights, property, or safety of Broxap Limited, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.
            To business associates. This includes but is not limited to transport companies, installation companies, and credit checking companies.
            <br/><br/>
            YOUR RIGHTS
            <br/><br/>
            You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. You can exercise your right to prevent such processing by ticking the relevant box on the form on which we collect your data (the order form). You can also exercise the right at any time by contacting us at datamanager@broxap.com. Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.
            <br/><br/>
            HOW LONG WILL WE KEEP YOUR PERSONAL DATA?
            <br/><br/>
            We will keep your personal data for as long as you are a customer or supplier of Broxap Ltd. We may keep your data for longer than the periods provided. If we cannot delete it for legal, regulatory or technical reasons, we will make sure that your privacy is protected and only use it for those purposes.
            <br/><br/>
            CHANGES TO OUR PRIVACY POLICY
            <br/><br/>
            Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail.
            <br/><br/>
            CONTACT
            <br/><br/>
            Questions, comments and requests regarding this privacy policy are welcomed and should be e-mailed to datamanager@broxap.com.
            <br/><br/>
            REV.1: 06.06
          </p>
        </section>       
      </div>
      
      <!-- end content wrap -->
      
      <div id="back-to-top">
        <a href="#top">
          <i class="ui-arrow-up"></i>
        </a>
      </div>
    </main>
    <!-- end main wrapper -->

  </body>
</template>

<script>

export default {
    mounted(){

    }
}

</script>

<style lang="scss">
.T4W {
    margin-top: 12rem!important;
    margin: auto;
    position: relative;
    display: block;
    width: 90vw;
}
</style>
<template>
  <div class="softvideo-container">
    <h2 class="softvideo-title">
      Client testimonial
    </h2>
    <p class="softvideo-sub-title">
      Don't just take our word for it when we say we are good. Click the video below and hear from Matt Kelter as he talks about how CyberKiln worked with Time 4 Sport UK to develop both a Data Management System, which functions as their back-office for the business, and we designed and developed a cross-platform mobile application that is available to download from both the Apple App Store and the Google Play Store.
      <br/>
    </p>
      <!-- <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/787536187?h=80aec1c755&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Time4Sport Uk review of CyberKiln">
        </iframe>         
      </div>  -->

      <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/806718269?h=1134691e20&amp;badge=0&amp;muted=0&amp;autoplay=0&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="CyberKiln - Time4Sport_1"></iframe></div>
  </div>  
</template>

<script>
export default ({
  components: {
  }
})
</script>

  

<style lang="scss">
@import '../styles/base/_mixins.scss';
.softvideo-container {
  display: block;
  position: relative;
  margin: 5vw auto;
  width: 60vw;
  padding: 2em 4em 4em 4em;
  border-radius: 25px;
  background-image: linear-gradient(to bottom right, #fc6129, #debd16);

  @include breakpoint-small-mobile {
    width: 95vw;
    padding: 1em 1em 1em 1em;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @include breakpoint-mobile {
    width: 95vw;
    padding: 1em 1em 1em 1em;
    margin-top: 6vh;
    margin-bottom: 6vh;
  }

  @include breakpoint-tablet {
    width: 80vw;
    padding: 1em 2em 1em 2em;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  @include breakpoint-large-tablet {
    width: 80vw;
    padding: 1em 2em 1em 2em;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  @include breakpoint-small-laptop {
    width: 80vw;
  }

  @include breakpoint-laptop {
    width: 80vw;
  }

  iframe {
    cursor: pointer;
    border-radius: 20px;
  }
}

.softvideo-title {
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0vw;
  text-align: center;
}

.softvideo-sub-title {
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vw;
  margin-bottom: 2vw;
  text-align: center;
}
</style>
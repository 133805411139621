<template>
<div class="contact-hero">
  <div class="container" style="padding-bottom: 3em;">
    <h1 class="contact-header-text">Get in <br> touch</h1>
    <div class="gradient-box-container">
      <div class="form-container gradient-box mt-48 mb-48">
           <Grid center>
        <Column :column-width="70" :below-tablet-width="100">
          <Grid pad :class="['contact-form', errors ? 'errors' : '']">
            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!nameError" for="name">Name</label>
                <label v-if="nameError" class="errorLabel" for="name"
                  >* You must enter a name</label
                >

                <input
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  :class="[nameError ? 'error' : '']"
                  v-model="name"
                  required
                />
              </div>
            </Column>
            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label for="company">Company</label>
                <input
                  type="text"
                  v-model="company"
                  placeholder="Company Name"
                  name="company"
                />
              </div>
            </Column>

            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!emailError" for="email">Email</label>
                <label v-if="emailError" class="errorLabel" for="email"
                  >* You must enter either a phone or valid email</label
                >
                <input
                  type="email"
                  v-model="email"
                  :class="[emailError ? 'error' : '']"
                  placeholder="Email"
                  name="email"
                />
              </div>
            </Column>

            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!telephoneError" for="telephone">Telephone</label>
                <label v-if="telephoneError" class="errorLabel" for="telephone"
                  >* You must enter either a phone or valid email</label
                >
                <input
                  type="number"
                  :class="[telephoneError ? 'error' : '']"
                  placeholder="Phone Number"
                  name="telephone"
                  v-model="telephone"
                />
              </div>
            </Column>

            <Column>
              <div class="form-group-full">
                <label v-if="!messageError" for="message">Your Message</label>
                <label v-if="messageError" class="errorLabel" for="message"
                  >* You must enter a message</label
                >
                <textarea
                  name="message"
                  placeholder="Your Message"
                  :class="[messageError ? 'error' : '']"
                  rows="8"
                  v-model="message"
                ></textarea>
              </div>
            </Column>

            <Column :column-width="100">
              <a
                :class="['pulse-button button', sending ? 'sending' : '']"
                href="#"
                @click.prevent="sendMessage"
                >{{ sendButtonText }}</a
              >

              <p
                :class="[
                  'label',
                  errors || sendFailure ? 'error' : 'success',
                  !sendSucceeded && !errors && !sendFailure ? 'hide' : ''
                ]"
              >
                &nbsp;
                <span v-if="errors"
                  >Please fix the issues above and click
                  <b>Send Message</b> again</span
                >
                <span v-if="sendSucceeded"
                  >Your message was successfully sent. We will get back to you
                  shortly</span
                >

                <span v-if="sendFailure">{{ sendFailure }}</span>
              </p>
            </Column>
          </Grid>
        </Column>
      </Grid>
      </div>
     
    </div>
    
  </div>

</div>
  
</template>


<script>
export default {
  name: "contact",
  data() {
    return {
      splashImageUrl: "/images/backgrounds/map-background.jpg",
      name: "",
      company: "",
      email: "",
      telephone: "",
      message: "",
      nameError: false,
      emailError: false,
      telephoneError: false,
      messageError: false,
      sending: false,
      sendFailure: "",
      sendSucceeded: false
    };
  },
  computed: {
    errors() {
      return (
        this.nameError ||
        this.emailError ||
        this.telephoneError ||
        this.messageError
      );
    },
    sendButtonText() {
      return this.sending ? "Sending..." : "Send Message";
    }
  },
  methods: {
    validEmail(email) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    isEmptyText(input) {
      return input == null || input.trim() === "";
    },
    sendMessage() {
      // If we are already sending...
      if (this.sending)
        // Ignore
        return;

      // Store this
      let me = this;

      // Clear send errors
      this.sendFailure = "";
      this.sendSucceeded = false;

      // Check we have a name
      this.nameError = this.isEmptyText(this.name);

      // Email or telephone must be provided
      this.emailError = this.telephoneError =
        !this.validEmail(this.email) && this.isEmptyText(this.telephone);

      // Check we have a message
      this.messageError = this.isEmptyText(this.message);

      // If we have any errors...
      if (this.errors) {
        // Scroll to top of form
        document.querySelector(".above-contact-form").scrollIntoView({
          behavior: "smooth",
          block: "end" // scroll to bottom of target element
        });

        // Stop there
        return;
      }

      // Good to go... try sending email and setting sending status
      this.sending = true;
      const axios = require('axios').default;

      axios
        .post("/sendemail", {
          name: this.name,
          company: this.company,
          email: this.email,
          telephone: this.telephone,
          message: this.message
        })
        .then(function(response) {
          // No longer sending
          me.sending = false;

          console.log("got response");
          console.log(response);

          // Make sure response says "Sent ok :)"
          if (response.data == "Sent ok :)") {
            // All good
            me.sendSucceeded = true;

            // Clear all fields
            me.name = me.company = me.email = me.telephone = me.message = "";
          } else {
            // Doh... unknown response
            me.sendFailure = "Unknown response from mail sender server.";
          }
        })
        .catch(function(error) {
          // No longer sending
          me.sending = false;

          console.log("got error");
          console.log(error.response);

          // Doh... show error
          me.sendFailure = me.isEmptyText(error.response.data)
            ? error.response.statusText
            : error.response.data;
        });
    }
  }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

 .gradient-box-container {
  width: 45vw;
  z-index: -1;
  margin: -5px; /* !important */
  border-radius: 1em;
  background: linear-gradient(to right, red, orange);
  height: fit-content;

  @include breakpoint-small-mobile {
    width: 85vw;
  }

  @include breakpoint-mobile {
    margin-top: 5vw;
    margin: 0px;
    width: 100%;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 5vw;
    margin: 0px;
    width: 100%;
  }

  .gradient-box {
    display: block;
    align-items: center;
    width: 45vw;
    margin: auto;
    position: relative;
    padding: 2em;
    box-sizing: border-box;
    color: #FFF;
    background-image: linear-gradient(144deg, #f4573d, #ddc214);
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 1em;
    height: fit-content;
    overflow: hidden;

    @include breakpoint-small-mobile {
      width: 100%;
      padding: 1em;
    }

    @include breakpoint-mobile {
      width: 100%!important;
    }
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      width: 100%!important;
    }
  }
}

.message-button-container {
  margin-top: 2vw;
  margin-bottom: 4vw;
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  @include breakpoint-mobile {
    margin-top: 10vw;
    margin-bottom: 10vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 10vw;
    margin-bottom: 10vw;
  }
}

.contact-hero {   
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 55%;
  height: fit-content;

  @include breakpoint-mobile {
    margin-top: 14vw;
    background-position: right top;    
    background-size: 250% 25%;
    padding-bottom: 5vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 11vw;
    background-position: right top;    
    background-size: 180% 25%;
  }

  .contact-header-text{
    margin-top: 2em;
    font-size: 5rem;
    color: #f29b48;

    @include breakpoint-small-mobile {
      font-size: 3rem;
    }

    @include breakpoint-mobile {
      margin-top: 30vw;
      font-size: 14vw;
      margin-bottom: 12vw;
    }
    @include breakpoint-tablet {
      margin-top: 30vw;
      font-size: 8vw;
      margin-bottom: 4vw;
    }

    @include breakpoint-laptop {
      font-size: 3rem;
    }
  }    

  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }  

  /* Button resets and style */
  button {
    margin: 15px auto;
    font-family: "Montserrat";
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 100px;
    padding: 15px 20px;
    border: 0px solid #000;   
  }

  .hs-button {
    margin: 15px auto !important;;
    font-family: "Montserrat"!important;
    font-size: 20px!important;
    color: #ffffff!important;
    cursor: pointer!important;
    border-radius: 100px!important;
    padding: 15px 20px!important;
    border: 0px solid #000!important; 
    animation: borderPulse 2000ms infinite ease-out, colorShift 10000ms infinite ease-in!important;
    @include breakpoint-mobile {
      width: 50vw!important;
      margin-left: auto!important;
      margin-right: auto!important;
      position: relative!important;
      display: block!important;
    }
  }

  .pulse-button {
    margin: 15px auto;
    font-size: 1rem;;
    color: #ffffff;
    cursor: pointer;
    border-radius: 100px;
    padding: 15px 20px;
    border: 0px solid #000; 
    animation: borderPulse 2000ms infinite ease-out, colorShift 10000ms infinite ease-in;

    @include breakpoint-mobile {
      width: 50vw;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      display: block;
    }
  }

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }
}
</style>
<template>
  <div class="introduction">
    <div class="introduction-container">
      <div class="introduction-text-container">
        <h1>
          Making the ordinary, 
          <br> <em>extraordinary</em>
        </h1>
        <p>
          From strategy and design, right through to the development and deployment, everything we do is tailor made to the individual business requirement. With over 24 years experience in the web and software industry, we know how to identify key objectives, advise on the best action plan and then deliver results.
          <br><br>
          We believe in honest, no jargon and straight to the point support. By having a wide range of knowledge and experience, we are able to provide the best solution that our clients are looking for. Our customer care and support is why we are one of the fastest growing software companies in North Staffordshire, with a reputation for not only being specialists in our field, but for also looking after our client's business like our own.
           
        </p>
        <router-link to="Contact" class="link-button-container">
          <div class="pulse-button" >Contact Us</div>
        </router-link>
      </div>

      <div class="waves-container">
        <div class="blob-container intro-blob">
          <!-- Blob 4 -->
          <img class="blob image four" src="@/img/home/blob1image.png" alt="Newcastle Under Lyme Business Boost Awards Winners 2021">

          <!-- Blob 5 -->
          <img class="blob image five" src="@/img/home/blob2image.png" alt="Staffordshire Chamber of Commerce Business Awards 2022 Finalist">

          <!-- Blob 6 -->
          <img class="blob image six" src="@/img/home/blob6image.png" alt="Stoke Live Business Live Business Awards 2022 Finalist">

          <svg class="blob colour four" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path fill="#fdc06af7" d="M34.6,-43.9C42.3,-34.8,44.2,-21.5,51.6,-5.3C59,10.9,71.9,30,69,44.4C66.1,58.9,47.4,68.7,28.8,72.7C10.3,76.6,-8.1,74.6,-25.3,68.7C-42.6,62.8,-58.7,53,-70.4,38.1C-82,23.1,-89.2,3.1,-87.4,-16.8C-85.5,-36.6,-74.6,-56.2,-58.5,-63.8C-42.4,-71.5,-21.2,-67.2,-3.9,-62.6C13.5,-58,26.9,-53.1,34.6,-43.9Z" transform="translate(100 100)" />
        </svg>   
              
        </div>
          
        </div>
      </div>
  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">

</style>
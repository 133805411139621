<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="OurClients"
    itemscope
    itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->   
    <main class="main-wrap">
      <div class="content-wrap">
        <ClientGrid/>
        <Testimonials/> 
      </div>
    </main>
  </body>
</template>

<script>
import ClientGrid from "@/components/ClientGrid.vue";
import Testimonials from '@/components/Testimonials.vue';
    export default {
    name: "OurClients",
    components: {
        ClientGrid,
        Testimonials
    }
    };

</script>

<style lang="scss">

</style>

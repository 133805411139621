<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="about"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
    <div class="our-clients-intro-container">
      <div class="our-clients-intro-text-container">
        <h1>Say hello to the people that power the <em>CyberKiln</em></h1>
      </div>      
    </div>

    <div id="services">    
      <!-- Desktop Timeline Container -->
      <div id="glider">
      
        <!-- Chris Desktop -->
        <div class="glider-item one blue ">
          <img src="@/img/branding/chris.png" alt="CyberKiln Founder and Managing Director Chris Daniels">
          <div class="glider-year">
            <p>Chris Daniels</p>
            <div class="glider-content">
              <h4>
                Founder and Managing Director of CyberKiln. Chris built CyberKiln with
                a vision to not only deliver quality results and service for clients, but to
                also give back to Stoke. 
                <br><br>
                Chris' passion is to share in the success of CyberKiln to regenerate Stoke, by 
                teaching young people how to use creative technology and provide opportunities to 
                work in the creative industry.
                <br><br>
                It is Chris' life goal to regenerate Stoke-on-Trent as a 
                creative technology power house, with CyberKiln's glass bottle kiln office 
                being part of that regeneration.
              </h4>
            </div>              
          </div>            
        </div>

        <!-- Luke Malpass Desktop -->
        <div class="glider-item two orange">
          <img src="@/img/branding/luke.png" alt="CyberKiln Technical Director Luke Malpass">
          <div class="glider-year">
            <p>Luke Malpass</p>
            <div class="glider-content">
              <h4>
                Technical Director of CyberKiln. Luke has always had a love for technology, 
                even from the age of 10, when he taught himself how to code using C back in 1997. Since then 
                Luke has learned over 40 programming languages and it comes as natural to him as speaking English.
                <br><br>
                Luke's passion is to share what he has learned so that others can go out and create amazing things
                of their own, and maybe one day make a career out of it just like he does. </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div id="services"> 
      <!-- Desktop Timeline Container -->
      <div id="glider">

        <!-- Nat Desktop -->
        <div class="glider-item one orange-reverse Nat">
          <img src="../img/branding/nat.png" alt="CyberKiln PA to MD and Office Manager">
          <div class="glider-year">
            <p>Natalie Snape</p>
            <div class="glider-content">
              <h4>
                Personal Assistant to the Managing Director of CyberKiln. Natalie joined the team in January 2021 having spent eight years in the education sector after graduating from Staffordshire University Psychology honours degree. Natalie is not only responsible for the organisation and scheduling of CyberKiln, but Natalie also runs the office administration, client delivery and financials.
                <br><br>
                Natalie is passionate about being meticulous and has a keen attention to detail. Natalie likes to put these skills to good use by ensuring the highest level of service is provided to our clients.
              </h4>
            </div>              
          </div>            
        </div>

        <!-- Luke Padbury Desktop -->
        <div class="glider-item two blue-reverse">
          <img src="../img/branding/lukep.png" alt="CyberKiln Senior Software Developer Luke Padbury">
          <div class="glider-year">
            <p>Luke Padbury</p>
            <div class="glider-content">
              <h4>
                Senior Software Developer of CyberKiln. Luke joined the team in August 2022 having spent the previous 5 years in various software development roles. Luke graduated from Staffordshire University Computer Games Programming in 2017 and has a growing passion for creative digital media. Software has been a natural fit for Luke and he has loved every minute of the journey and progressing his skills in the software industry.
                <br><br>
                Luke's skills include project management, Cross-platform CI/CD, full-stack development as well as video game development particularly AI, systems development and rapid prototyping.</h4>
            </div>
          </div>
        </div>  
        
      </div>
    </div>
    

    <!-- Mobile Timeline Container -->
    <div id="mobile-glider">
      <!-- Chris Mobile-->
      <div class="mobile-glider-item one chris" @click="toggleShow('chris')">

        <img class="mobile-glider-image right" src="@/img/branding/chris.png" id="ChrisMobileImage" alt="CyberKiln Founder and Managing Director Chris Daniels">
        <div class="mobile-glider-year">
          <p>Chris Daniels</p>
        </div>
        
      </div>

      <transition name="fade"> 

          <div class="mobile-glider-content" ref='chrisText' v-show="isShowing.chris">
            
              <h4>
                Founder and Managing Director of Cyber Kiln. Chris built CyberKiln with
                a vision to not only deliver quality results and service for clients, but to
                also give back to Stoke. 
                <br><br>
                Chris' passion is to share in the success of CyberKiln to regenerate Stoke, by 
                teaching young people how to use creative technology and provide oppurtunities to 
                work in the creative industry.
                <br><br>
                It is Chris' life goal to regenrate Stoke-on-Trent as a 
                creative technology power house, with CyberKiln's glass bottle kiln office 
                being part of that regeneration.
              </h4>

          
        </div>

      </transition>      
    </div>

    <!-- Border for between image in mobile (set to display none in standard css) -->
    <hr class="mobile-item-seperator">

    <div id="mobile-glider">
      <!-- LukeP Mobile -->
      <div class="mobile-glider-item two luke mobile-orange" @click="toggleShow('luke')">
        <img class="mobile-glider-image left" src="@/img/branding/luke.png" id="LukeMobileImage" alt="CyberKiln Technical Director Luke Malpass">
        <div class="mobile-glider-year blue-text right">
          <p>Luke Malpass</p>
        </div>
      </div>
      <transition name="fade">

          <div class="mobile-glider-content" v-show="isShowing.luke">
            <div style="margin: 0em 2em;">
              <h4>
                Technical Director of Cyber Kiln. Luke has always had a love for technology, 
                even from the age of 10, when he taught himself how to code using C back in 1997. Since then 
                Luke has learned over 40 programming languages and it comes as natural to him as speaking English.
                <br><br>
                Luke's passion is to share what he has learned so that others can go out and create amazing things
                of their own, and maybe one day make a career out of it just like he does.
              </h4>
            </div>
        </div>
          
      </transition>
    </div>

    <!-- Border for between image in mobile (set to display none in standard css) -->
    <hr class="mobile-item-seperator">

    <div id="mobile-glider">
      <!-- Nat Mobile -->
      <div class="mobile-glider-item two luke" @click="toggleShow('nat')">
        <img class="mobile-glider-image right" src="@/img/branding/nat.png" id="NatMobileImage" alt="CyberKiln PA to MD and Office Manager">
        <div class="mobile-glider-year">
          <p>Natalie Snape</p>
        </div>

      </div>
      <transition name="fade">
        <div class="mobile-glider-content" v-show="isShowing.nat">
        <h4>
          Personal Assistant to the Managing Director of CyberKiln. Natalie joined the team in January 2021 having spent eight years in the education sector after graduating from Staffordshire University Psychology honours degree. Natalie is not only responsible for the organisation and scheduling of CyberKiln, but Natalie also runs the office administration, client delivery and financials.
          <br><br>
          Natalie is passionate about being meticulous and has a keen attention to detail. Natalie likes to put these skills to good use by ensuring the highest level of service is provided to our clients.
        </h4>
        </div>
      </transition> 
    </div>

    <!-- Border for between image in mobile (set to display none in standard css) -->
    <hr class="mobile-item-seperator">

    <div id="mobile-glider">
          <!-- LukeP Mobile -->
        <div class="mobile-glider-item two luke mobile-orange" @click="toggleShow('lukeP')">
          <img class="mobile-glider-image left" src="@/img/branding/lukep.png" id="LukePMobileImage" alt="CyberKiln Senior Software Developer Luke Padbury">
          <div class="mobile-glider-year blue-text right">
            <p>Luke Padbury</p>
          </div>
        </div>
          <transition name="fade">

              <div class="mobile-glider-content" v-show="isShowing.lukeP">
                <div style="margin: 0em 2em;">
                  <h4>
                    Senior Software Developer of CyberKiln. Luke joined the team in August 2022 having spent the previous 5 years in various software development roles. Luke graduated from Staffordshire University Computer Games Programming in 2017 and has a growing passion for creative digital media. Software has been a natural fit for Luke and he has loved every minute of the journey and progressing his skills in the software industry.
                    <br><br>
                    Luke's skills include project management, Cross-platform CI/CD, full-stack development as well as video game development particularly AI, systems development and rapid prototyping.
                  </h4>
                </div>
            </div>
              
          </transition>
    </div>
    
     <!-- Border for between image in mobile (set to display none in standard css) -->
    <hr class="mobile-item-seperator">

    <div class="image-mosaic mt-32">
      <div
        class="card"
        style="background-image: url('/team/1.JPG')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/2.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/3.JPG')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/7.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/4.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/5.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/6.jpg')"
      ></div>      
      <div
        class="card"
        style="background-image: url('/team/8.JPG')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/9.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/11.JPG')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/10.jpg')"
      ></div>      
      <div
        class="card"
        style="background-image: url('/team/12.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/13.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/14.JPG')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/15.jpg')"
      ></div> 
      <div
        class="card"
        style="background-image: url('/team/16.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/17.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/18.jpg')"
      ></div>
      <div
        class="card"
        style="background-image: url('/team/19.jpg')"
      ></div>       
    </div>

    <Section class="about-pulse-button">
      <router-link to="Contact" class="link-button-container">
        <h1>Speak to us today.</h1>
        <div class="pulse-button" >Contact Us</div>
      </router-link>
    </Section> 
    

  </body>
</template>

<script>

export default {
  data(){
      return{
        isShowing:{
          chris: false,
          luke: false,
          nat: false,
          lukeP: false
        }
      }
  },
  methods:{
   toggleShow(member){
      
      switch(member){
        case "chris":
          this.isShowing.chris = !this.isShowing.chris;
          break;
        case "luke":
          this.isShowing.luke = !this.isShowing.luke;
          break;
        case "nat":
          this.isShowing.nat = !this.isShowing.nat;
          break;
        case "lukeP":
          this.isShowing.lukeP = !this.isShowing.lukeP;
      }

   }
  },

   mounted(){ 
    }
};

</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';

// To be refactored outside of this style between custom and meettheteam.scss
  $main-color: #ff7600;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;
  $body-font: 'Montserrat', sans-serif;
  $heading-font: 'Montserrat', sans-serif;

  .our-clients-intro-container {
    margin-bottom: 4vw;
    padding-top: 6vw;
    background-image: url("../img/waves/Wave2c.svg");    
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;

    @include breakpoint-small-mobile {
      margin-top: 16vw;
    }

    @include breakpoint-mobile{
      margin-top: 10vw;
    }

    @include breakpoint-tablet{
      margin-top: 14vw;
    }

    @include breakpoint-extra-large {
      background-size: cover;
      padding-top: 6vw;
      margin-top: 0vw;
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   margin-top: 11vw;
    // }

    .our-clients-intro-text-container {
      margin-left: auto;
      margin-right: auto;
      width: 55vw;
      padding-top: 10vw;

      @include breakpoint-small-mobile {
        width: 85vw;
        padding-top: 20vw;
      }

      @include breakpoint-mobile{
        width: 85vw;
        padding-top: 20vw;
      }

      @include breakpoint-tablet{
        width: 85vw;
        padding-top: 20vw;
      }
    }

    .our-clients-subtext {
      width: 55vw;
      color: #fc8e27;

      @include breakpoint-mobile{
        width: 85vw;
        margin-top: 5vw;
      }

      @include breakpoint-tablet{
         width: 85vw;
         margin-top: 5vw;
      }

      em {
        color: #e9e5e5;
        font-style: normal;
      }
    }

    .our-clients-subtext-inverted {
      width: 55vw;
      color: #e9e5e5;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .image-left{
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      right: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }

    }

    .image-right{
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      left: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }

    }

  .blue {
    background-image: linear-gradient(to top right, #15212f, #213851);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

    img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      right: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      left: 4vw;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint-small-laptop {
        width: 42vw !important;
      }      
    }
  }

  .blue-reverse {
    background-image: linear-gradient(to top right, #15212f, #213851);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

     img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      left: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      right: 4vw;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint-small-laptop {
        width: 42vw !important;
      } 
    }
  }

  .orange {
    background-image: linear-gradient(to top right, #f74d1a, #ffe154);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

    img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      left: 0;

      @include breakpoint-mobile {
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      right: 4vw;
      color: #1f344b;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint-small-laptop {
        width: 42vw !important;
      } 
    }
    
  }

  .orange-reverse {
    background-image: linear-gradient(to top right, #f74d1a, #ffe154);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

    img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      right: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      left: 4vw;
      color: #1f344b;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint-small-laptop {
        width: 42vw !important;
      }       
    }
    
  }

  #glider {
    display: flex;
    z-index: 3;
    position: relative;

    @include breakpoint-small-mobile {
      display: none;
    }
    @include breakpoint-mobile{
      display: none;
    }

    @include breakpoint-tablet{
      display: none;
    }

    @include breakpoint-large-tablet{
      display: none;
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   display: none;
    // }

    h4 {
      color: white !important;

      @include breakpoint-laptop{
        font-size: 1rem;
      }

      @include breakpoint-large-laptop{
        font-size: 1.2rem;
      }
      @media only screen and (min-width: 1025px) and (max-width: 1800px) {
        font-weight: 100;    
      }      
    }
  }

  .glider-item {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 50%;
    max-height: 35vw;
    min-height: 35vw;
    color: $main-color;
    overflow: hidden;
    transition: width 0.5s ease;
    
    
    &:before, &:after {
      transform: translate3d(0, 0, 0);
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
    }

    &:after {
      opacity: 1;
      transition: opacity 0.5s ease;
    }

    &:before {
      z-index: 1;
      opacity: 0;
      transform: translate3d(0, 0, 0) translateY(50%);
      transition: opacity 0.5s ease, transform 0.5s ease;
      
    }

    &:hover {
      width: 80% !important;

      @include breakpoint-small-laptop {
        width: 90% !important;
      }
      img {
        opacity: 1;
      }
      
      
      &:after {
        opacity: 0;
      }

      &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0) translateY(0);
        transition: opacity 1s ease, transform 1s ease 0.25s;
        
      }
      
      .glider-content {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.75s ease 0.5s;
      }

      .glider-year{
        font-weight: bold;

        @include breakpoint-small-laptop {
          h4 {
            font-weight: normal;
          }
        } 
      }

      .glider-bg {
        filter: grayscale(0);
      }

    }

    &.Nat {
      .glider-content {
        @include breakpoint-large-laptop {
          width: 40vw;
        }        
      }
    }
  }

  .glider-content {
    transform: translate3d(0, 0, 0) translateY(25px);
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 2em !important;
    padding: 0.5em;
    opacity: 0;

    @include breakpoint-small-laptop {
      h4 {
        font-size: 1.4vw!important;
      }
    }

    @include breakpoint-extra-large {
      padding: 2em 0.5em;
    }

    p {
      margin-top: 3em !important;
    }
  }

  .glider-year {
    position: absolute;
    z-index: 1;
    
    p {
      font-size: 2.5vw;
      line-height: 1;
      font-weight: bold;
      font-style: $heading-font;
      text-shadow: 2px 2px black, 4px 4px #fff;
      text-align: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint-extra-large {
        font-size: 2.5vw!important;
      }
    }
  }

  .glider-bg {
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s ease;
    filter: grayscale(100%);
  }

  #mobile-glider {
       
    .mobile-orange{
      background-image: linear-gradient(to top right, #f74d1a, #ffe154);
      background-image: -moz-linear-gradient(top, #E01212, #000000);
      background-image: -ms-linear-gradient(top, #E01212, #000000);
      background-image: -o-linear-gradient(top, #E01212, #000000);
      background-image: -webkit-gradient(to top right, #E01212, #000000);
    }

    .mobile-blue{
      background-image: linear-gradient(to top right, #15212f, #213851);
      background-image: -moz-linear-gradient(top, #E01212, #000000);
      background-image: -ms-linear-gradient(top, #E01212, #000000);
      background-image: -o-linear-gradient(top, #E01212, #000000);
      background-image: -webkit-gradient(to top right, #E01212, #000000);
    }
  }

  .mobile-glider-item {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 50%;
    color: $main-color;
    overflow: hidden;
    transition: width 0.5s ease;
    height: 100% !important;

    @include breakpoint-small-mobile {
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    @include breakpoint-mobile{
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    @include breakpoint-tablet{
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    @include breakpoint-large-tablet{
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }
    
    
    &:before, &:after {
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
    }

    &:before {
      z-index: 1;      
    }

    &:hover {
      .mobile-glider-bg {
        filter: grayscale(0);
      }
    }

    .mobile-glider-image{
      @include breakpoint-small-mobile {
        width: 50%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }
      }      
    
      @include breakpoint-mobile {
        width: 50%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }          
      }

      @include breakpoint-tablet {
        width: 40%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }          
      }

      @include breakpoint-large-tablet {
        width: 40%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }          
      }
    }
  }

  .mobile-glider-content {
    transform: translate3d(0, 0, 0) translateY(25px);
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 5em !important;
    margin-bottom: 2em !important;
    padding: 0.5em;

    @include breakpoint-small-mobile {
      margin-top: 0em !important;
      margin: 0em 2em;
    }

    @include breakpoint-mobile {
      margin-top: 0em !important;
      margin: 0em 2em;
    }

    @include breakpoint-tablet{
      margin-top: 0em !important;
      margin: 0em 2em;
      padding: 0em 0em 2em 0em;
    }

    @include breakpoint-large-tablet{
      margin-top: 0em !important;
      margin: 0em 2em;
      padding-bottom: 4em;
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   margin-top: unset !important;
    // }   
    
    h1 {
      text-transform: uppercase;
      color: $main-color;
      font-size: 1.44rem;
      font-weight: normal;
    }

    h4 {
      color: White;
      font-size: 4vw;
      font-weight: normal;

      @include breakpoint-small-mobile {
        margin-top: 0em;
      }

      @include breakpoint-mobile {
        margin-top: 0em;
      }

      @include breakpoint-tablet{
        margin-top: 0em;
        font-size: 2.6vw;
      }

      @include breakpoint-large-tablet{
        margin-top: 0em;
        font-size: 2.6vw;
      }
    }

    p{
      color: $tertiary-color !important;
      font-size: 0.9em !important;
    }
  }

  .mobile-glider-year {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    @include breakpoint-small-mobile {
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }

    @include breakpoint-mobile {
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }

    @include breakpoint-tablet{
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }

    @include breakpoint-large-tablet{
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   width: 45vw;
    //   top: unset;
    //   margin-top: -6vw;
    // }    
    
    p {
      font-size: 2.5vw;
      line-height: 1;
      font-weight: bold;
      font-style: "Montserrat", sans-serif;
      text-shadow: 2px 2px black, 4px 4px #fff;
      text-align: center;

      @include breakpoint-small-mobile {
        font-size: 8vw;
      }

      @include breakpoint-mobile {
        font-size: 8vw;
      }
      
      @include breakpoint-tablet{
        font-size: 6vw;
      }

      @include breakpoint-large-tablet{
        font-size: 4vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   font-size: 8vw;
      // }   
      
    }

    &.blue-text{
      color: #1f344b;
    }

    &.right{

      left: 73vw;

    }
  }

  .mobile-glider-bg {
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s ease;
    filter: grayscale(100%);
  }

  .mobile-item-seperator{
    @include breakpoint-small-mobile {
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }
    @include breakpoint-mobile {
      //border-top: 1vh solid white; 
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }

    @include breakpoint-tablet{
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }

    @include breakpoint-large-tablet{
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }

    display:none;
  
  }

  .grid-container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 auto;
    
    @include breakpoint-mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint-tablet{
      grid-template-columns: repeat(1, 1fr);
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   grid-template-columns: repeat(1, 1fr);
    // }

    .tile img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tile:nth-child(1){
      grid-column: span 3;
      grid-row: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // }      
    }

    .tile:nth-child(2),
    .tile:nth-child(3){
      grid-column: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }

    .tile:nth-child(4),
    .tile:nth-child(5){
      grid-column: span 3;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
         width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }    
    .tile:nth-child(6){
      grid-column: span 3;
      grid-row: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }
    .tile:nth-child(7){
      grid-column: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }
    @media screen and (max-width: 650px){
      .container{
          display: block;
      }
    }
  }

  $main-color: #e15a2a;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c; 

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }


</style>

<template>
 <div class="welcome">

    <div class="waves-container wave1">

      <div class="blob-container welcome-blob">
        <!-- Blob 1 -->
        <svg class="blob colour one" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad1" x1="25%" y1="100%" x2="50%" y2="0%">
              <stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
              <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
            </linearGradient>
          </defs>
          <path fill="url(#grad1)" d="M41.6,-52.4C54.2,-39.1,64.7,-26.2,67.1,-11.9C69.5,2.3,63.8,18,53.3,25.8C42.8,33.6,27.6,33.7,12.2,43.6C-3.1,53.5,-18.5,73.2,-34.4,75.6C-50.2,78,-66.6,63,-76.8,44.7C-87,26.4,-90.9,4.8,-85.2,-12.9C-79.6,-30.7,-64.4,-44.7,-48.6,-57.4C-32.8,-70.1,-16.4,-81.5,-0.9,-80.4C14.6,-79.3,29.1,-65.7,41.6,-52.4Z" transform="translate(100 100)" />          
        </svg>
        <img class="blob image one" src="@/img/home/blob3image.png" alt="A group photo of CyberKiln team members - Chris Daniels Founder and Managing Director, Natalie Snape Personal Assistant and Office Manager, Luke Malpass Technical Director">

        <!-- Blob 2 -->
        <svg class="blob colour two" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path fill="#ffeeb9b3" d="M66.1,-19.3C72.7,-1.1,56.3,26.8,35.1,40.4C13.9,53.9,-12,53,-29.5,40.4C-47,27.7,-56.1,3.3,-49.7,-14.5C-43.4,-32.3,-21.7,-43.4,4,-44.7C29.7,-46,59.5,-37.5,66.1,-19.3Z" transform="translate(100 100)" />
        </svg>
        <img class="blob image two" src="@/img/home/blob4image.png" alt="Managing Director Chris Daniels is welcoming Senior Software Developer Luke Padbury to CyberKiln">

        <div class="wave-text-container">        
          <h1>
            Welcome to CyberKiln
          </h1>
          <h2>
            The home of dedicated bespoke web & software specialists based in Stoke-on-Trent.
          </h2>       
        </div>   

        <!-- Blob 3 -->
        <svg class="blob colour three" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path fill="#ffeeb9b3" d="M34.6,-43.9C42.3,-34.8,44.2,-21.5,51.6,-5.3C59,10.9,71.9,30,69,44.4C66.1,58.9,47.4,68.7,28.8,72.7C10.3,76.6,-8.1,74.6,-25.3,68.7C-42.6,62.8,-58.7,53,-70.4,38.1C-82,23.1,-89.2,3.1,-87.4,-16.8C-85.5,-36.6,-74.6,-56.2,-58.5,-63.8C-42.4,-71.5,-21.2,-67.2,-3.9,-62.6C13.5,-58,26.9,-53.1,34.6,-43.9Z" transform="translate(100 100)" />
        </svg>

        <img class="blob image three" src="@/img/home/blob5image.png" alt="CyberKiln Founder Chris Daniels and Office Manager Natalie Snape at Staffordshire University 2021 expo">      
      </div>

           
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>
<template>
<div class="testimonial-container">
    <div class="testimonial-header-container">

      <!-- Orb 1 -->
      <svg class="orb colour one" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="grad1" x1="25%" y1="100%" x2="50%" y2="0%">
            <stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
            <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
          </linearGradient>
        </defs>
        <path fill="url(#grad1)" d="M69.4,-22.2C78.9,6.7,67.9,42.5,43.8,59.9C19.8,77.2,-17.4,76.2,-41.1,58.5C-64.8,40.8,-75,6.6,-65.9,-21.8C-56.8,-50.1,-28.4,-72.6,0.8,-72.8C29.9,-73.1,59.9,-51.1,69.4,-22.2Z" transform="translate(100 100)" />
      </svg>

      <!-- Orb 2 -->
      <svg class="orb colour two" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="grad2" x1="25%" y1="100%" x2="50%" y2="0%">
            <stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
            <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
          </linearGradient>
        </defs>
        <path fill="url(#grad2)" d="M69.4,-22.2C78.9,6.7,67.9,42.5,43.8,59.9C19.8,77.2,-17.4,76.2,-41.1,58.5C-64.8,40.8,-75,6.6,-65.9,-21.8C-56.8,-50.1,-28.4,-72.6,0.8,-72.8C29.9,-73.1,59.9,-51.1,69.4,-22.2Z" transform="translate(100 100)" />
      </svg>

      <!-- Orb 3 -->
      <svg class="orb colour three" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="grad3" x1="25%" y1="100%" x2="50%" y2="0%">
            <stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
            <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
          </linearGradient>
        </defs>
        <path fill="url(#grad3)" d="M69.4,-22.2C78.9,6.7,67.9,42.5,43.8,59.9C19.8,77.2,-17.4,76.2,-41.1,58.5C-64.8,40.8,-75,6.6,-65.9,-21.8C-56.8,-50.1,-28.4,-72.6,0.8,-72.8C29.9,-73.1,59.9,-51.1,69.4,-22.2Z" transform="translate(100 100)" />
      </svg>

      <div class="header-text">
        <h1>Our<br>happy<br>clients</h1>
      </div>

   <span class="review-container review-one two-column">
        <div class="review-image-container">
          <img id="reviewimage01" class="review-image" src="@/img/testimonials/atom.jpg">
        </div>
        <div class="review-text-container">
          <a id="review01">
            "The knowledge that CyberKiln have is simply astonishing. 
            We have worked with them on a few projects now and the websites, 
            coding and app development they offer is very impressive and 
            bespoke for all their clients.
            I would highly recommend giving them a call and having a chat 
            with them for your next project." 
          </a>
          <h4>ATom Production - &#x2605;&#x2605;&#x2605;&#x2605;&#x2605; Google review</h4>
        </div>
      </span>

      <span class="review-container review-two two-column">
        <div class="review-image-container">
          <img id="reviewimage01" class="review-image" src="@/img/testimonials/stokelock.jpg">
        </div>
        <div class="review-text-container">
          <a id="review02">
            "The website that Cyber Kiln produced is of very good quality and 
            Chris was very receptive to any feedback that I gave. The process was quick, 
            efficient and it resulted in a very professional and easy to use website. 
            Would not hesitate to recommend Cyber Kiln in the future." 
          </a>
          <h4>Stoke Locksmith - &#x2605;&#x2605;&#x2605;&#x2605;&#x2605; Google review</h4>
        </div>
      </span>

      <span class="review-container review-three two-column">
        <div class="review-image-container">
          <img id="reviewimage01" class="review-image" src="@/img/testimonials/simpson.jpg">
        </div>
        <div class="review-text-container">
          <a id="review02">
            "We chose Cyberkiln to build our new website and definitely made the right choice. 
            From initial consultation where by explaining how we can gain more clients by 
            speeding up our website to completion. We are delighted with the finished site 
            and our customer enquiries have increased ten fold.
            <br> <br>
            Couldn't be happier. Thanks guys." 
          </a>
          <h4>M Simpson & Sons - &#x2605;&#x2605;&#x2605;&#x2605;&#x2605; Google review</h4>
        </div>
      </span>

      <span class="review-container review-five two-column">
        <div class="review-image-container">
          <img id="reviewimage01" class="review-image" src="@/img/testimonials/charlie.jpg">
        </div>
        <div class="review-text-container">
          <a id="review02">
            "Very professional and amazing web design would highly recommend" 
          </a>
          <h4>Charlie Browns Hair Studio - &#x2605;&#x2605;&#x2605;&#x2605;&#x2605; Google review</h4>
        </div>
      </span>      

    </div>


    <Section class="review-pulse-button">
      <router-link to="Contact" class="link-button-container">
        <h1>Speak to us today.</h1>
        <div class="pulse-button" >Contact Us</div>
      </router-link>
    </Section>

  </div>
</template>

<script>
export default{
    mounted(){

    }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';

 .testimonial-container {
    display: block;
    position: relative;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(
      to left, #162431, #16243100), 
      url("../img/testimonials/quotation.png");
    background-repeat: no-repeat;
    background-size: 15%;
    background-position: top center;    

    @include breakpoint-tablet{
      height: fit-content;
      margin-top: 5rem;
    }

    @include breakpoint-laptop{
      top: 4em;
    }
    

    .testimonial-header-container {
      margin: 0 auto;
      margin-top: 24rem!important;
      position: relative;
      display: block;
      width: 100%;
      text-align: center;
      z-index: 5;

      @include breakpoint-small-mobile {
        padding-bottom: 0;
        margin-top: 4rem !important;
      }

      @include breakpoint-mobile{
        width: 98vw;
        height: auto;
        margin-top: 0!important;
      }
      @include breakpoint-tablet {
        width: 98vw;
        height: fit-content;
        margin-top: 0!important;
      }   

      @include breakpoint-large-tablet  {
        width: 98vw;
        height: auto;
        margin-top: 0!important;
      } 

      .header-text {
        padding-bottom: 2em;
        position: relative;

        h1 {

          @include breakpoint-laptop{
            font-size: 2.5em;
          }

          @include breakpoint-tablet {
            text-align: center;
            
          }

          @include breakpoint-mobile {
            text-align: center;
            
          }

           @include breakpoint-large-tablet  {
            text-align: center;
            
          }
        }
      }

      .review-container {
        position: absolute;       
        
        @include breakpoint-small-mobile {
          position: relative;
          margin-left: auto;
          margin-right: auto;
          width: 100vw;
          margin-top: 0vw;
        }

        @include breakpoint-mobile {
          position: relative;
          margin-left: auto;
          margin-right: auto;
          width: 100vw;
          margin-top: 0vw;
        }
        
        @include breakpoint-tablet {
          position: relative;
          margin-left: auto;
          margin-right: auto;
          width: 100vw;
          margin-top: 0vw;          
        }

        @include breakpoint-large-tablet {
          position: relative;
          margin-left: auto;
          margin-right: auto;
          width: 100vw;
          margin-top: 0vw;
        }

        &:hover {
          @media (min-width: 769px) {
          
            .review-image-container {
              padding: 1em;
            }
            .review-image {
              height: 8em;
              width: 8em;
              border-color: #e15a2a;
              opacity: 1;
              z-index: 3;

              @include breakpoint-laptop{
                height: 6em;
                width: 6em;
                border-color: #e15a2a;
                opacity: 1;
              }
            }
            
            .review-text-container {
              opacity: 1;
              z-index: 2;
            }

            h4 {
              color: #e15a2a!important;
            }
          }
        } 

        .review-image-container {
          height: 10em;
          width: 10em;
          padding: 2em;
          transition: padding 1s ease;

          @include breakpoint-small-mobile {
            display: none;
            height: 25vw;
            width: 20vw;
            padding: 4vw;
          }

          @include breakpoint-mobile {
            display: none;
            height: 25vw;
            width: 20vw;
            padding: 4vw;
          }

          @include breakpoint-tablet {
            display: none;
            height: 25vw;
            width: 20vw;
            padding: 4vw;
          }

          @include breakpoint-large-tablet  {
            display: none;
            height: 25vw;
            width: 20vw;
            padding: 4vw;
          }
        } 

        .review-image {
          position: absolute;
          border-radius: 50%;
          border: 5px solid #909090;
          height: 6em;
          width: 6em;
          opacity: 0.6;
          transition: width 1s ease, height 1s ease, opacity 1s ease;

          @include breakpoint-laptop{
            height: 6em;
            width: 6em;
          }

          @include breakpoint-tablet {
            opacity: 1;
            height: 20vw;
            width: 20vw;
          }

          @include breakpoint-mobile {
            opacity: 1;
            height: 20vw;
            width: 20vw;
          }

          @include breakpoint-large-tablet  {
            opacity: 1;
            height: 20vw;
            width: 20vw;
          }
        }

        .review-text-container {
          height: fit-content;
          width: 40vw;
          display: block;
          opacity: 0;
          transition: opacity 1s ease;
          margin: 2em 0px;
          background-color: #162431d6;
          z-index: 1;

          @include breakpoint-laptop{            
            width: 30vw;

            a {
              font-size: 1rem;
            }
          }

          @include breakpoint-large-tablet {
            opacity: 1;
            width: 80vw;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0vw;

            a {
              font-size: 2.5vw;
              line-height: 1;
            }
          }

          @include breakpoint-tablet {
            opacity: 1;
            width: 80vw;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0vw;

            a {
              font-size: 2.5vw;
              line-height: 1;
            }
          }

          @include breakpoint-mobile {
            opacity: 1;
            width: 80vw;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0vw;

            a {
              font-size: 1rem;
              line-height: 1;
            }
          }

          @include breakpoint-small-mobile {
            opacity: 1;
            width: 80vw;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0vw;

            a {
              font-size: 1rem;
              line-height: 1;
            }
          }
          
          h4 {
            transition: color 1s ease;

            @include breakpoint-large-tablet {
              font-size: 4.5vw;
              text-align: center;
            }

            @include breakpoint-tablet {
              font-size: 4.5vw;
              text-align: center;
            }

            @include breakpoint-mobile {
              font-size: 4.5vw;
              text-align: center;
            }

            @include breakpoint-small-mobile {
              font-size: 6.5vw;
              text-align: center;
            }
          }
        }
      }

      .review-one {
        top: -20em;
        left: 4em;

        @include breakpoint-laptop{
          top: -15em;
        }

        @include breakpoint-large-tablet  {
          top: 0;
          left: 0;
        }

        @include breakpoint-tablet {
          top: 0;
          left: 0;
        }

        @include breakpoint-mobile {
          top: 0;
          left: 0;
        }

        @include breakpoint-small-mobile {
          top: 0;
          left: 0;
        }
      }

      .review-two {
        bottom: -15em;
        right: 5em;

        @include breakpoint-small-mobile {
          top: 0;
          right: 0;
        }

        @include breakpoint-mobile {
          top: 0;
          right: 0;
        }

        @include breakpoint-tablet {
          top: 0;
          right: 0;
        }

        @include breakpoint-large-tablet  {
          top: 0;
          right: 0;
        }

        @include breakpoint-small-laptop  {
          display: none;
        }

        @include breakpoint-laptop{
          right: 20em;
        }
      }

      .review-three {
        top: -5vw;
        left: 0;

        @include breakpoint-large-tablet  {
          top: 0;
          left: 0;
        }

        @include breakpoint-tablet {
          top: 0;
          left: 0;
        }

        @include breakpoint-mobile {
          top: 0;
          left: 0;
        }

        @include breakpoint-small-mobile {
          top: 0;
          left: 0;
        }
      }

      .review-four {
        top: 9vw;
        right: 30em;

        @include breakpoint-large-tablet  {
          top: 0;
          right: 0;
        }

        @include breakpoint-tablet {
          top: 0;
          right: 0;
        }

        @include breakpoint-mobile {
          top: 0;
          right: 0;
        }

        @include breakpoint-small-mobile {
          top: 0;
          right: 0;
        }

        // @media only screen and (min-width: 769px){
        //   top: 8vw;
        //   right: -30vw;
        // }
      }

      .review-five {
        top: -15em;
        right: 10em;

        @include breakpoint-small-mobile {
          display: none;
        }

        @include breakpoint-mobile {
          display: none;
        }

        @include breakpoint-tablet {
          display: none;
        }

        @include breakpoint-large-tablet  {
          display: none;
        }

        @include breakpoint-small-laptop  {
          top: unset;
          bottom: -10em;
          right: 8em;
        }

        @include breakpoint-laptop{
          right: 0em;
        }
        
        .review-text-container {
          width: 20em!important;                   
        }
      } 
    }
  }

  .orb.colour {
		height: 10em;
    position: absolute;
    z-index: 0;
    @media (max-width: 991px) {
      display: none;
    }
	}

  .orb.colour.one {
		top: 10em;
    left: 4em;
    transform: rotate(35deg);

    @include breakpoint-laptop{
      height: 5em;
    }
	}

  .orb.colour.two {
		bottom: -10em;
    height: 6em;
    right: 18em;
    transform: rotate(149deg);

    @include breakpoint-laptop{
      height: 3em;
      right: 15em;
    }
	}

  .orb.colour.three {
		top: -20em;
    height: 12em;
    right: 55em;
    transform: rotate(319deg);

    @include breakpoint-small-laptop {
      left: 40em;
      right: unset;
    }

    @include breakpoint-laptop{
      height: 6em;
      right: 40em;
    }    
	}
  $main-color: #e15a2a;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;

  .review-pulse-button {
    margin-top: 20rem;
    height: auto;

    @include breakpoint-large-tablet {
      margin: 0;
    }

    @include breakpoint-tablet{
      margin-top: 5rem;
      padding: unset !important;
    }

    @include breakpoint-mobile {
			top: 0vw;
      padding: 2rem 0rem;
      height: 50vw;
      margin-top: 0rem;
		}

    @include breakpoint-small-mobile {
      top: 0vw;
      padding: 2rem 0rem;
      height: 78vw;
      margin-top: 0rem;
    }
  }

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }
</style>
<template>
<!-- Header -->
  <header
    class="nav nav--always-fixed"
    id="reduced"
    itemtype="http://schema.org/WPHeader"
    itemscope
  >
    <div class="nav__holder nav--align-center">
      <div class="container-fluid container-semi-fluid">
        <div class="flex-parent">
          <div class="nav__header clearfix">
            <!-- Logo -->
            <div class="logo-wrap local-scroll">
              <router-link to="/" class="nav-link">
                <img
                  class="nav-logo"
                  src="@/img/branding/header-logo.svg"
                  srcset="@/img/branding/header-logo.svg"
                  alt="cyberkiln logo"
                  itemtype="http://schema.org/Organization"
                  itemscope
                />
              </router-link>
            </div>

            <button
              type="button"
              class="nav__icon-toggle"
              id="nav__icon-toggle"
              data-toggle="collapse"
              data-target="#navbar-collapse"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
            </button>
          </div>
          <!-- end nav-header -->

          <!-- Navbar -->
          <nav
            id="navbar-collapse"
            class="nav__wrap collapse navbar-collapse"
            itemtype="http://schema.org/SiteNavigationElement"
            itemscope="itemscope"
          >

            <ul class="nav__menu local-scroll" id="onepage-nav">
              <!-- About -->
              <li class="nav__dropdown">
                <a class="nav-link"
                aria-expanded="false"
                aria-haspopup="true"
                role="button"
                >About
                </a>
                <i
                  v-bind:class="(activeClass)"
                  class="nav__dropdown-trigger"
                  role="button"
                  @click="toggledroparrow()"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <ul class="nav__dropdown-menu">
                  <li style="list-style: none; float: none;">
                    <router-link to="MeetTheTeam">
                      Meet the Team
                      <!-- <span class="nav__dropdown-menu-label">Meet The Team</span> -->
                    </router-link>
                  </li>
                  <!-- <li>
                    <router-link to="OurStory">
                      <span class="nav__dropdown-menu-label">Our Story</span>
                    </router-link>
                  </li> -->
                  <!-- <li>
                    <router-link to="CSR">
                      <span class="nav__dropdown-menu-label">CSR</span>
                    </router-link>
                  </li> -->
                </ul>
              </li>

              <!-- OUR CLIENTS -->
              <li>
                <router-link to="OurClients" class="nav-link">Our Clients</router-link> 
              </li>

              <!-- OUR Services -->
              <li class="nav__dropdown">
                <a class="nav-link"
                aria-expanded="false"
                aria-haspopup="true"
                role="button"
                >Services
                </a>
                <i
                  v-bind:class="(activeClass)"
                  class="nav__dropdown-trigger"
                  role="button"
                  @click="toggledroparrow()"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <ul class="nav__dropdown-menu">
                  <li class="option" style="list-style: none; float: none; text-align: left !important;">
                    <router-link to="Software">
                      Software Development
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Websites">
                      Websites Design & Development
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Services">
                      Hosting, Domain & Emails
                    </router-link>
                  </li>
                  <!-- <li class="option">
                    <router-link to="Training">
                      Training
                    </router-link>
                  </li> -->
                  <li class="option">
                    <router-link to="Consultancy">
                      Consultancy
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Contact us -->
              <li>
                <router-link to="Contact" class="nav-link">Contact</router-link>
              </li> 

              <!-- Mobile Socials -->
                <div class="nav__mobile-socials d-lg-none">
                  <div class="socials">
                    <a
                      href="#"
                      class="social social-facebook"
                      aria-label="facebook"
                      title="facebook"
                      target="_blank"
                    >
                      <i class="ui-facebook"></i>
                    </a>
                    <a
                      href="#"
                      class="social social-linkedin"
                      aria-label="linkedin"
                      title="linkedin"
                      target="_blank"
                    >
                      <i class="ui-linkedin"></i>
                    </a>
                    <a
                      href="#"
                      class="social social-instagram"
                      aria-label="instagram"
                      title="instagram"
                      target="_blank"
                    >
                      <i class="ui-instagram"></i>
                    </a>
                  </div>
              </div>

              
                
            </ul>

          </nav>
          <!-- end nav-wrap -->

          <!-- Socials -->
          <div class="nav__socials flex-child d-none d-lg-block">
            <div class="socials right">
              <a
                href="https://www.facebook.com/CyberKiln"
                class="social social-facebook"
                aria-label="facebook"
                title="facebook"
                target="_blank"
              >
                <i class="ui-facebook"></i>
              </a>
              <a
                href="https://uk.linkedin.com/company/cyberkiln"
                class="social social-linkedin"
                aria-label="linkedin"
                title="linkedin"
                target="_blank"
              >
                <i class="ui-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/cyberkiln/"
                class="social social-instagram"
                aria-label="instagram"
                title="instagram"
                target="_blank"
              >
                <i class="ui-instagram"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- end flex-parent -->
      </div>
      <!-- end container -->
    </div>
  </header>
  <!-- end navigation -->

</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return{
         activeClass: 'ui-arrow-down'
    }
  },
  methods:{
      toggledroparrow(){
        
        if(this.activeClass == 'ui-arrow-down')
        {
          this.activeClass = 'ui-arrow-up'
        }
        else
        {
          this.activeClass = 'ui-arrow-down'
        }
      }
  },
  mounted: function(){

  }
};
</script>

<style lang="scss">

</style>
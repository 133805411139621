@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="WhatWeDo"
    itemscope
    itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->    
    <main class="main-wrap">
      <div class="content-wrap">
        <div class="what-we-do-intro-container">
          <div class="what-we-do-intro-text-container">
            <h1>Bespoke website <br> design and development</h1>
          <h3 class="wwd-subtext"> Now more than ever it is important for businesses to have a website that not only is fast and looks great, but that actively works as a 24-hour sales person that is consistently converting visitors into customers.
          </h3>
          </div>
        </div>

        <!-- Web Design Section -->
        <div class="what-we-do-container webdesign">
          <Section class="pt2 what-we-do-grid">
            <div class="services-cont">
              <div class="service-inverted what-we-do">
                <div class="center">
                  <h2 class="what-we-do-title">
                    Web Design
                  </h2>
                  <h4 class="what-we-do-sub-title">
                    
                  </h4>

                  <div class="wwd-grid-container wwd-grid-one ">
                    <div class="wwd-grid-image-container">
                      <div class="software-grid web-design side d50 l40">
                        <div class="software-grid-card">
                          <a>
                            <img src="@/img/services/webdes.jpg" alt="CyberKiln working on website design for client Razorfade"/>
                          </a>
                        </div>

                        <div class="software-grid-card">
                          <a>
                            <img src="@/img/services/webdes_2.jpg" alt="Laptop showing the web design of CyberKiln's client RR Groundworks"/>
                          </a>
                        </div>

                        <div class="software-grid-card">
                          <a>
                            <img src="@/img/services/webdes_3.jpg" alt="Mobile showing the web design responsive for CyberKiln's client Charlie Browns Hair Studio"/>
                          </a>
                        </div>

                        <div class="software-grid-card">
                          <a>
                            <img src="@/img/services/webdes_4.jpg" alt="Tablet showing responsive web design of CyberKiln's client M Simpson and Sons website."/>
                          </a>
                        </div>
                        
                      </div>
                    </div>
                    <div class="wwd-grid-text-container">
                      <p>
                        Websites design is a vital aspect of building a successful website. Fail to get it right and not only are you wasting your hard-earned money, but you turn away customers as well as devalue your brand.
                        <br><br> 
                        Your design has to be carefully tailored to your business. It has to target your audience with 
                        the right style, colour palette, layout, navigation the list is endless. We work 
                        with our clients to see their business from their customer's perspective, and identify 
                        the best design to suit them. 
                        <br><br>  
                        We take the hassle away from our clients and allow them 
                        to get on with what they do best, while we create amazing custom web designs that really 
                        stand out from the crowd. 
                      </p>
                      
                    </div>
                  </div>

                  <h3 class="what-we-do-contact-body-text"> 
                    To refresh your web design, speak to us <em>today</em>.              
                  </h3>
                  <div>
                    <router-link to="Contact" class="contact-button-container">
                      <div class="pulse-button" >Contact Us</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>


        <!-- Web Development Section -->
        <div class="what-we-do-container webdev">
          <Section class="pt2 what-we-do-grid">
            <div class="services-cont">
              <div class="service-inverted what-we-do web-dev">
                <div class="center">
                  <h2 class="what-we-do-sub-title">
                    Web Development  
                  </h2>
                  <h4 class="what-we-do-sub-title">
                    When your business relies upon a steady stream of new customers, your website code needs to function and perform at its best, as well as having a good web design.
                  </h4>      

                  <p class="what-we-do-body-text">                    
                    By working with experienced web developers like us, we ensure that all of the important code that goes on in the background of your website is at its best, so that your website loads quickly and securely for your customers.
                    <br><br>
                    The world of websites is always evolving, with new practices and powerful tools being developed every day, and businesses who can't spend the time keeping their websites uo-to-date will be losing out on valuable sales. It's a well known fact that website performance is a key criteria when grading a website's SEO score. At CyberKiln we keep up-to-date on all of the latest criteria, technology and best practices so that we can provide the best service and advice to our customers.
                  </p>
                  <div class="masonry-container web-skills">
                    <div class="masonry">
                      <div class="masonry-tile"><span><img src="@/img/services/HTML5.png" alt="HTML 5 logo"></span></div>
                      <div class="masonry-tile"><span><img src="@/img/services/CSS3.png" alt="CSS 3 logo"></span></div>
                      <div class="masonry-tile"><span><img src="@/img/services/JS.png" alt="Javascript logo"></span></div>
                      <div class="masonry-tile"><span><img src="@/img/services/VUEJS-service.png" alt="Vue.js logo"></span></div>
                      <div class="masonry-tile"><span><img src="@/img/services/WordPress.png" alt="WordPress logo"></span></div>
                      <div class="masonry-tile"><span><img src="@/img/services/laravel.png" alt="Laravel logo"></span></div>
                      
                    </div>
                  </div>

                  <h3 class="what-we-do-contact-body-text"> 
                    Do you want your next website to truly make an impact? 
                    Contact us <em>today</em>.              
                  </h3>
                  <div>
                    <router-link to="Contact" class="contact-button-container">
                      <div class="pulse-button" >Contact Us</div>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>
          </Section>
        </div>

        <!-- Website Benefits -->
        <div class="what-we-do-container web-support">
          <Section class="pt2 what-we-do-grid">
            <div class="services-cont web-packages">
              <div class="service-inverted web-support">
                <div class="center">
                  <h2 class="what-we-do-title">
                    The benefits of CyberKiln built websites
                  </h2>
                </div>

                <div class="services-cont web-sup">
                  <div class="services-web-support">
                    <div>
                      <i class="service__icon o-money-box-1" alt="piggy bank savings"></i>
                      <h4 class="service__title">Value for money</h4>
                      <p class="service__text">
                        By using the right solutions for the job, we are able to make sure the budget is being spent where it truly matters.
                      </p>
                    </div>
                  </div>

                  <div class="services-web-support">
                    <div>
                      <i class="service__icon o-call-contact-1" alt="call"></i>
                      <h4 class="service__title">Customer Support</h4>
                      <p class="service__text">
                        We offer direct support packages to give our clients that all important peace of mind.
                      </p>
                    </div>
                  </div>

                  <div class="services-web-support">
                    <div>
                      <i class="service__icon o-smartphone-1" alt="speed"></i>
                      <h4 class="service__title">Responsive design</h4>
                      <p class="service__text">
                        All of our websites are designed and coded to look great on mobile, tablet, laptop and desktop. 
                      </p>
                    </div>
                  </div>

                  <div class="services-web-support">
                    <div>
                      <i class="service__icon o-security-shield-1" alt="protection"></i>
                      <h4 class="service__title">Security as standard</h4>
                      <p class="service__text">
                        To protect from malicious attackers, all of our websites have the latest encryption and protection.
                      </p>
                    </div>
                  </div>

                  <div class="services-web-support">
                    <div>
                      <i class="service__icon o-sales-up-1" alt="engineer"></i>
                      <h4 class="service__title">High performance</h4>
                      <p class="service__text">
                        Our websites are coded to provide the fastest speeds, even on mobile networks.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Section>
        </div>

        <StepProcess/>
        <ClientSlider/>
      </div>
    </main>
  </body>
</template>

<script>
import StepProcess from "@/components/StepProcess.vue";
import ClientSlider from "@/components/ClientSlider.vue";
export default {  
  components: {
    ClientSlider,
    StepProcess,
  }
};
</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';

  $main-color: #e15a2a;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;
  $quarteriary-color: #eea424;

  .web-skills {
    .masonry {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      align-items: center;
      column-gap: 2rem;

      @include breakpoint-small-mobile {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
      }

      @include breakpoint-mobile {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
      }

      @include breakpoint-tablet {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
      }

      @include breakpoint-large-tablet {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
      }
    }
  }

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }
</style>
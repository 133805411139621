<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="ourstory"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->

    <div class="our-story-intro-container">
      <div class="our-story-intro-text-container">
        <h1>Our Story</h1>
        <h4>
          We’ve been supporting businesses by delivering some of the most effective and creative bespoke websites and software solutions since June 2019.
          <br><br>
          We’ve come a long way since then. What began with a marketing database for the Rover Group has grown to become a diverse range of data-driven solutions deployed in 15 countries for a broad range of clients across banking, insurance, utilities, automotive and the public sector.
          <br><br>
          The success and growth that we’ve built to date is just the beginning: we continue to innovate, explore new markets and develop solutions to tackle the new and divergent data challenges that continue to emerge.
        </h4>
      </div>      
    </div>
  </body>
</template>

<script>

export default {
   mounted(){ 
    }
};

</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  $main-color: #ff7600;
  $main-color-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;
  $body-font: 'Montserrat', sans-serif;
  $heading-font: 'Montserrat', sans-serif;

  .our-story-intro-container {
    margin-bottom: 4vw;
    padding-top: 6vw;
    background-image: url("../img/waves/Wave3a.svg");    
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;

    @include breakpoint-mobile {
      margin-top: 14vw;
    }
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      margin-top: 11vw;
    }

    .our-story-intro-text-container {
      margin-left: auto;
      margin-right: auto;
      width: 55vw;
      padding-top: 10vw;

      h4 {
        line-height: 1.3;
      }

      @include breakpoint-mobile {
        width: 85vw;
        padding-top: 20vw;
      }
    }

    .our-clients-subtext {
      width: 55vw;
      color: #fc8e27;

      @include breakpoint-mobile {
        width: 85vw;
        margin-top: 5vw;
      }

      em {
        color: #e9e5e5;
        font-style: normal;
      }
    }

    .our-clients-subtext-inverted {
      width: 55vw;
      color: #e9e5e5;
    }
  }

</style>

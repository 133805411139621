import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import MeetTheTeam from "../views/MeetTheTeam.vue";
import OurStory from "../views/OurStory.vue";
import OurClients from "../views/OurClients.vue";
import Software from "../views/Software.vue";
import Websites from "../views/Websites.vue";
import Services from "../views/Services.vue";
import Training from "../views/__Training.vue";
import Consultancy from "../views/Consultancy.vue";
import T4W from "../views/Time4WellbeingPrivacyPolicy.vue";
import SSG from "../views/SunshineGymPrivacyPolicy.vue";
import Contact from "../views/Contact.vue";
import Privacy from "../views/Privacy.vue";
//Import all other pages to route here

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/MeetTheTeam',
        name: 'MeetTheTeam',
        component: MeetTheTeam
    },
    {
        path: '/OurStory',
        name: 'OurStory',
        component: OurStory
    },    
    {
        path: '/OurClients',
        name: 'OurClients',
        component: OurClients
    },
    {
        path: '/Software',
        name: 'Software',
        component: Software
    },    
    {
        path: '/Websites',
        name: 'Websites',
        component: Websites
    },
    {
        path: '/Services',
        name: 'Services',
        component: Services
    },
    // {
    //     path: '/Training',
    //     name: 'Training',
    //     component: Training
    // },
    {
        path: '/Consultancy',
        name: 'Consultancy',
        component: Consultancy
    },
    {
        path: '/Contact',        
        name: 'Contact',
        component: Contact,          
    },
    {
        path: '/Time4Wellbeing_Privacy_Policy',
        name: 'Time4Wellbeing_Privacy_Policy',
        component: T4W
    },
    {
        path: '/Sunshine_Gym_App_Privacy_Policy',
        name: 'Sunshine_Gym_App_Privacy_Policy',
        component: SSG
    },
    {
        path: '/Privacy',
        name: 'Privacy',
        component: Privacy
    },

    // Add new routes here
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition){
        return{top: 0};
    },
    routes
})

export default router

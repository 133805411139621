<template>
  <div class="soft-benefits-container">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container softdev">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do softdev">
            <div class="center">
              <h2 class="what-we-do-title">
                The benefits of bespoke software development from CyberKiln
              </h2>
              <div class="side-by-side">                    
                <div class="software-grid tools side sm-90 d50 l40">
                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/NET-core-logo.png" alt="Logo for Microsoft dot Net Core"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/avalonia.png" alt="Logo for Avalonia"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/Javascript.png" alt="Logo for javascript coding language"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/Csharp.png" alt="Logo for c sharp programming language"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/postgresql-logo.png" alt="Logo for post gre SQL database"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/MAUI.png" alt="Logo for Microsoft dot Net MAUI app development"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/Electron.png" alt="Logo for Electron app software framework"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/docker.png" alt="Logo for docker software"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/java.png" alt="Logo for java software development"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/Go.png" alt="Logo for Go Programming language"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/Cplusplus.png" alt="Logo for c plus plus programming language"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/python.png" alt="Logo for python programming language"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/php.png" alt="Logo for php scripting language"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/ruby.png" alt="Logo for Ruby coding language"/>
                    </a>
                  </div>

                  <div class="software-grid-card">
                    <a>
                      <img src="@/img/services/xamarin.png" alt="Logo for Xamarin app development"/>
                    </a>
                  </div>

                  <div class="software-grid-card lg-lap-hide">
                    <a>
                      <img src="@/img/services/NodeJs.png" alt="Logo for node js coding language"/>
                    </a>
                  </div>
                </div>

                <div class="side sm-90 d50 l60">
                  <ul class="what-we-do-body-text softdev low-pad">
                    <li>
                      <p>
                        <em>A dedicated and experienced support team.</em> We don't just build the software for your business, but we stay on-hand to help your business with the right advice and support.
                      </p>
                    </li>
                    <li>
                      <p>
                        <em>All of our team are based in our Stoke-on-Trent office.</em> Our clients take great value in being able to come to our office, meet the team face-to-face, and be able to share ideas and make plans.
                      </p>
                    </li>
                    <li>
                      <p>
                        <em>Increase business profitability, stability and efficiency.</em> With fully integrated and tailor made systems, it allows you and your business to get on with the work that really adds value, so you don't waste time and money on things that can be handled automatically.
                      </p>
                    </li>
                    <li>
                      <p>
                        <em>Scaleable</em> One of the most well known benefits of custom developed software, is that it can scale with your business, so that you won't be forced to find, and pay for, yet another solution that requires time to implement, training to use, and further disrupt your business. 
                      </p>
                    </li>
                    <li>
                      <p>
                        <em>Reliable and secure.</em> A major benefit of bespoke software is that it is less likely to suffer from hackers and cyber attacks. As commercial off-the-shelf solutions are publicly accessible, hackers finding ways to breach those solutions is even greater. With custom solutions, the information they need to hack the systems is not so accessible, and so they will more likely move on to more susceptible software solutions.
                      </p>
                    </li>
                    <li>
                      <p>
                        <em>Cost.</em> Although bespoke software can seem expensive initially, the savings made over time by not having to pay the increasing cost of licenses, upgrades and subscriptions, is what makes bespoke software more cost effective. Plus, as it is tailored to fit your business, you save time and money by not having to change your business processes to suit generic off-the-shelf solutions.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>  
</template>

<script>
export default ({
  components: {
  }
})
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
.soft-benefits-container {
  width: 100vw;
  background-image: url("@/img/waves/Wave6d.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 1vw 0vw 5vw 0vw;

  @include breakpoint-small-mobile {
    background-image: url("@/img/waves/Wave6d-small-mobile.svg");
    margin-top: 5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include breakpoint-mobile {
    background-image: url("@/img/waves/Wave6d-mobile.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  @include breakpoint-tablet {
    background-image: url("@/img/waves/Wave6d-tablet.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  @include breakpoint-large-tablet {
    background-image: url("@/img/waves/Wave6d-tablet.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  @include breakpoint-small-laptop {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  @include breakpoint-laptop {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  @include breakpoint-large-laptop {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  @include breakpoint-desktop {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  @include breakpoint-extra-large {
    background-image: url("@/img/waves/Wave6d-xlarge-desk.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5rem;
  }

  .softdev {
    padding-top: 5vw;
    
    @include breakpoint-extra-large {
      padding-top: 0vw; 
    }
  }
}

</style>
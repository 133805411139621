@@ -0,0 +1,919 @@
<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="WhatWeDo"
    itemscope
    itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->    
    <main class="main-wrap">
      <div class="content-wrap">

        <!-- Software Development Intro Section -->
        <div class="what-we-do-intro-container">
          <div class="what-we-do-intro-text-container">
            <h1>High quality results,<br> bespoke software solutions</h1>
          <h3 class="wwd-subtext"> We work with our clients to map out their current business process and then we collaborate to create innovative solutions that they need. By taking this approach, we accurately pinpoint where we can improve business efficiency, and then we can develop the right solutions that enable our client's businesses to function and perform better than they did before.</h3>
          </div>
        </div>        

        
        <SoftBenefits/>
        <SoftVideo/>
        <StepProcess/>
        <ClientSlider/>
      </div>
    </main>
  </body>
</template>

<script>
import StepProcess from "@/components/StepProcess.vue";
import ClientSlider from "@/components/ClientSlider.vue";
import SoftVideo from "@/components/SoftVideo.vue";
import SoftBenefits from "@/components/SoftBenefits.vue";
export default {
  components: {
    ClientSlider,
    StepProcess,
    SoftVideo,
    SoftBenefits
  }
} 
</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';

 
</style>


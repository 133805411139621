<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="t4w"
    itemscope
    itemtype="http://schema.org/WebPage"
  >
  <!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->
  <main class="main-wrap">
      <div class="content-wrap">
        <section class="T4W">
              <h2>Time 4 Wellbeing Privacy Policy</h2>
              <p>The Time 4 Wellbeing App is a product of Time 4 Sport UK Limited. The following is Time 4 Sport UK's Data Protection, GDPR for Education & Care Customers policy.

                  We have updated our Privacy Policy in line with the General Data Protection Regulations
                  coming into effect from 25th May 2018.
                  
                  To continue to provide the level of service that we pride ourselves on, we will store data
                  relating to your organisation and pupils.
                  Any data is held in the strictest confidence and would never be made available to any third
                  party unnecessarily.
                  Our business practice ensures that any data held is available only to individuals who need it.
                  Here is what you need to know …
                  <br/>
                  <b>What Data do Time 4 Sport hold about my Organisation?</b><br>
                  - Contact & billing details - Name, organisation, job role, telephone and email address
                  - Children / participant details - First and last Names, Dates of Birth (if provided), Classes
                  and information relating to tracking participation (specifically progress) in Physical
                  Education and school sport.
                  <br><b>How is the Data Stored?</b> <br>
                  Time 4 Sport use carefully selected services and our own software to manage our data, all of
                  which are held on servers within the EU and held behind encrypted Secure Socket layers
                  (SSL certificates). This software includes: - CRM software for managing customer
                  conversations, enquiries and new business leads - Accounting software for customer
                  invoicing and managing company finances - Session Planning Software (Honeycomb) for the
                  planning, delivery and assessment of participation in Physical Education and School Sport.
                  <br><b>How long is the Data Stored?</b><br>
                  Time 4 Sport will only retain data for the period of time necessary to fulfil our agreed
                  services with the customer. At the end of our contract or agreement with you, any and all
                  personal data will be securely deleted within a reasonable period of time.
                  You can request to see, remove or update any data held at any time by contacting us on
                  01782 409677 or via email to info@time4sportuk.com
                  Thanks again for your continued support.
                  Matt Kelter Protection Officer, Time 4 Sport UK Ltd.</p>
        </section>       
      </div>
      
      <!-- end content wrap -->
      
      <div id="back-to-top">
        <a href="#top">
          <i class="ui-arrow-up"></i>
        </a>
      </div>
    </main>
    <!-- end main wrapper -->

  </body>
</template>

<script>

export default {
    mounted(){

    }
}

</script>

<style lang="scss">
.T4W {
    margin-top: 12rem!important;
    margin: auto;
    position: relative;
    display: block;
    width: 90vw;
}
</style>